import React from "react";
import "./orderInvoice.css";
import firebase from "firebase";
import Loader from "../../Components/Loader/Loader";

import ReactToPrint from "react-to-print";
import Invoice from "../../Components/Invoice/Invoice";

export default class OrderInvoice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			order: "",
			loading: true,
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection("orders")
			.doc(this.props.match.params.id)
			.get()
			.then((doc) => {
				console.log(doc.data());
				var order = doc.data();
				order.id = doc.id;
				this.setState({
					order,
					loading: false,
				});
			});
	}

	render() {
		return (
			<div className='invoice-container'>
				{this.state.loading ? (
					<Loader />
				) : (
					<div className='invoice-container-main'>
						<div className='invoice-printer '>
							<ReactToPrint
								trigger={() => {
									// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
									// to the root node of the returned component as it will be overwritten.
									return <button className='print-button'>Print Invoice</button>;
								}}
								content={() => this.componentRef}
							/>
						</div>
						<Invoice ref={(el) => (this.componentRef = el)} order={this.state.order} />
					</div>
				)}
			</div>
		);
	}
}
