import React from "react";
import "./MiniNav.css";
import Lottie from "lottie-react-web";
import loading from "../../../assets/loading.json";
import firebase from "firebase";
import Loader from "../Loader/Loader";

export default class MiniNav extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			categories: [],
			loading: true
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection("settings")
			.onSnapshot((snap) => {
				snap.docChanges().forEach((change) => {
					this.setState({
						categories: change.doc.data().categories,
						loading: false
					});
				});
			});
	}
	render() {
		return (
			<div className="mini-container">
				{this.state.loading ? (
					<Loader />
				) : (
					<div className="mini-container-main">
						<a href="/NewArrivals" className="new">
							New Arrivals
						</a>
						{this.state.categories.map((cat, index) => {
							return (
								<div className="mini-content" key={index}>
									<div className="mini-content-context">
										<a href={"/Category/" + cat.name}>{cat.name}</a>
										<i className="fa fa-chevron-down fa-1x"></i>
									</div>
									<div className="category-options">
										{cat.subcategories.map((sub, index) => {
											return (
												<a href={"/Category/" + cat.name + "/" + sub.name} key={index}>
													<img src={sub.image} style={{ width: "30px", height: "30px" }} />
													<p>{sub.name}</p>
												</a>
											);
										})}
									</div>
								</div>
							);
						})}
						<a href="/Sale" className="sale">
							Sale
						</a>
						<a href="/Emboss" className="mini-content">
							Emboss
						</a>
						{/* <a href="/CorporateContact" className="mini-content">
							Corporate Gifting
						</a> */}
						<a href="/FranchiseContact" className="mini-content">
							Franchise Contact
						</a>
					</div>
				)}
			</div>
		);
	}
}
