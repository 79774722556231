import React from "react";
import "./Footer.css";
import marfitLogo from "../../../assets/marfit-logo.png";
import cards from "../../../assets/card.png";

export default class Footer extends React.Component {
	render() {
		return (
			<div className="footer">
				<div className="flex-container">
					<div className="container-1">
						<div className="marfit-detail">
							<img src={marfitLogo} alt="Marfit Logo" />
							<p>
								Marfit Stores are committed to providing each customer with the highest standard of customer service. Our goal is to sell finest exclusive leather and pu products. We
								will be introducing lot of unique products in different genres in the coming future. Keep an eye.
							</p>
						</div>
						<div className="company-terms">
							<div className="company-items">
								<h3>COMPANY</h3>
								<a href="/TrackOrder">Track Orders</a>
								<a href="#">About Us</a>
								<a href="/Contact">Contact</a>
							</div>

							<div className="company-items">
								<h3>POLICY & RULES</h3>
								<a href="/termsandcondition" target="_blank">
									Terms & Condition
								</a>
								<a href="/shippingpolicy" target="_blank">
									Shipping Policy
								</a>
							</div>

							<div className="company-items">
								<h3>SUPPORT</h3>
								<a href="tel:+919007589067">
									<i className="fas fa-phone-alt"></i>+91 98755 55937
								</a>
								<a href="mailto:info@marfit.com">
									<i className="fas fa-envelope"></i> info@marfit.in
								</a>
							</div>
						</div>
					</div>
					<div className="container-2">
						<div className="icons-pack">
							<a href="https://www.facebook.com/MarfitStores">
								<i className="fab fa-facebook-f"></i>
							</a>
							<a href="https://www.instagram.com/marfit.in/">
								<i className="fab fa-instagram"></i>
							</a>
						</div>
						<p>We Accept</p>
						<img src={cards} alt="CardsImage" />
					</div>
				</div>
				<div className="copyright">
					<p>
						&copy; Copyright 2020 . All rights reserved | Powered by{" "}
						<a style={{ color: "#FC864D" }} href="https://www.augendtech.com" target="_blank">
							Augend Tech
						</a>
					</p>
				</div>
			</div>
		);
	}
}
