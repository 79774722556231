import React from 'react';
import logo from "../../../assets/logo.png";
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import moment from 'moment';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff',
        height: 'auto'
    },
    section: {
        margin: 5,
        padding: 5,
        textAlign: "center",
    },
    headerView: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    },
    header: {
        fontSize: 16,
        fontWeight: "bold",
        textAlign: "center"
    },
    address: {
        fontSize: 10,
        textAlign: "center"
    },
    image: {
        width: 15,
        height: 15,
        marginRight: 5
    },
    invoiceSection: {
        marginTop: 10
    },
    billHeader: {
        flexDirection: "row",
        justifyContent: "space-between"
    },
    table: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        // borderTop: '0.2px solid #000',
        paddingTop: 2,
        paddingBottom: 2,
    },
    header: {
        borderTop: 'none',
    },
    bold: {
        fontWeight: 'bold',
    },
    col1: {
        width: '10%',
        fontSize: 9
    },
    col2: {
        width: '30%',
        fontSize: 9

    },
    col3: {
        width: '10%',
        fontSize: 9

    },
    col4: {
        width: '25%',
        fontSize: 9

    },
    col5: {
        width: '25%',
        fontSize: 9,
        textAlign: "right"

    },
});

// Create Document Component
export const InvoiceComponent = ({ products = [], totalPcs = 0, totalPrice = 0, discounts = [], totalDiscount = 0, pointsDiscount = 0, cardAmount = 0, cardNumber = 0, upiAmount = 0, cash = 0, customerName = "", billNo = 0, date = null, time = null, store }) => (
    <Document>
        <Page size={{ width: 280, height: 'auto' }} style={styles.page}>
            <View style={styles.section}>
                <View style={styles.headerView}>
                    <Image style={styles.image} src={logo} />
                    <Text style={styles.header}>Marfit</Text>
                </View>
                <Text style={styles.address}>{store.address}</Text>
                <Text style={styles.address}>+91-{store.cphone}</Text>
                <Text style={styles.address}>GSTIN/UIN: 19BCYPK3468D1Z1</Text>
                <Text style={styles.address}>State Name: {store.state}, Code: 19</Text>
                <Text style={styles.address}>E-Mail: marfitofficial@gmail.com</Text>
                <View style={styles.invoiceSection}>
                    <Text style={styles.address}>TAX INVOICE</Text>
                    <View style={styles.billHeader}>
                        <Text style={styles.address}>
                            Bill no: {billNo}
                        </Text>
                        <Text style={styles.address}>Time: {JSON.stringify(time)} hrs</Text>
                    </View>
                    <Text style={{ ...styles.address, textAlign: "left" }}>Date: {moment(date).format("DD/MM/YYYY")}</Text>
                    <Text style={{ ...styles.address, textAlign: "left" }}>Name: {customerName}</Text>
                </View>
                <View style={styles.table}>
                    <View style={[styles.row, styles.bold, styles.header]}>
                        <Text style={styles.col1}>Sl</Text>
                        <Text style={styles.col2}>Desc</Text>
                        <Text style={styles.col3}>Qty</Text>
                        <Text style={styles.col4}>Rate</Text>
                        <Text style={styles.col5}>Amount</Text>
                    </View>
                    {products?.map((row, i) => (
                        <View key={i} style={styles.row} wrap={false}>
                            <Text style={styles.col1}>
                                {i + 1}
                            </Text>
                            <Text style={styles.col2}>{row.batch}</Text>
                            <Text style={styles.col3}>{row.qty}</Text>
                            <Text style={styles.col5}>
                                Rs. {row.rate}
                            </Text>
                            <Text style={styles.col5}>Rs. {Math.round(row.total)}</Text>
                        </View>
                    ))}
                    {discounts.filter((discount) => discount.value > 0).map((discount) => <View style={{ ...styles.row }} wrap={false}>
                        <Text style={styles.col1}>

                        </Text>
                        <Text style={styles.col2}>
                            {discount.title}
                        </Text>
                        <Text style={styles.col3}></Text>
                        <Text style={styles.col4}>
                        </Text>
                        <Text style={styles.col5}>
                            Rs. {Math.round(discount.value)}
                        </Text>
                    </View>)}
                    {pointsDiscount > 0 && <View style={{ ...styles.row }} wrap={false}>
                        <Text style={styles.col1}>

                        </Text>
                        <Text style={styles.col2}>
                            Redeemed Points
                        </Text>
                        <Text style={styles.col3}></Text>
                        <Text style={styles.col4}>
                        </Text>
                        <Text style={styles.col5}>
                            Rs. {Math.round(pointsDiscount)}
                        </Text>
                    </View>}
                    <View style={{ ...styles.row, borderBottom: "1px solid #000", borderTop: "0.5px solid #000" }} wrap={false}>
                        <Text style={styles.col1}>

                        </Text>
                        <Text style={styles.col2}>Total</Text>
                        <Text style={styles.col3}>{totalPcs}</Text>
                        <Text style={styles.col4}>

                        </Text>
                        <Text style={styles.col5}>Rs. {Math.round(totalPrice - totalDiscount - pointsDiscount)}</Text>
                    </View>

                    <View style={{ ...styles.row }} wrap={false}>
                        <Text style={styles.col1}>
                            CGST
                        </Text>
                        <Text style={styles.col2}>
                            @9%
                        </Text>
                        <Text style={styles.col3}>On</Text>
                        <Text style={styles.col4}>
                            Rs. {Math.round(Number((totalPrice - totalDiscount - pointsDiscount) / 1.09).toFixed(2))}
                        </Text>
                        <Text style={styles.col5}>
                            Rs. {Math.round(Number((totalPrice - totalDiscount - pointsDiscount) - Number((totalPrice - totalDiscount - pointsDiscount) / 1.09).toFixed(2)).toFixed(2))}

                        </Text>
                    </View>
                    <View style={{ ...styles.row }} wrap={false}>
                        <Text style={styles.col1}>
                            SGST
                        </Text>
                        <Text style={styles.col2}>
                            @9%
                        </Text>
                        <Text style={styles.col3}>On</Text>
                        <Text style={styles.col4}>
                            Rs. {Math.round(Number((totalPrice - totalDiscount - pointsDiscount) / 1.09).toFixed(2))}
                        </Text>
                        <Text style={styles.col5}>
                            Rs. {Math.round(Number((totalPrice - totalDiscount - pointsDiscount) - Number((totalPrice - totalDiscount - pointsDiscount) / 1.09).toFixed(2)).toFixed(2))}
                        </Text>
                    </View>


                    {cardAmount > 0 && <View style={{ ...styles.row }} wrap={false}>
                        <Text style={{ ...styles.col2, textAlign: "left" }}>
                            Cr/Dr Card
                        </Text>
                        <Text style={styles.col1}>
                            :
                        </Text>
                        <Text style={{ width: "80%", fontSize: 9 }}>Rs. {cardAmount}</Text>

                    </View>}
                    {cardAmount > 0 && <View style={{ ...styles.row }} wrap={false}>
                        <Text style={{ ...styles.col2, textAlign: "left" }}>
                            Card No.
                        </Text>
                        <Text style={styles.col1}>
                            :
                        </Text>
                        <Text style={{ width: "80%", fontSize: 9, textAlign: "right" }}>{`XXXX XXXX XXXX ${cardNumber}`}</Text>
                    </View>}
                    {upiAmount > 0 && <View style={{ ...styles.row }} wrap={false}>
                        <Text style={{ ...styles.col2, textAlign: "left" }}>
                            UPI
                        </Text>
                        <Text style={styles.col1}>
                            :
                        </Text>
                        <Text style={{ width: "80%", fontSize: 9 }}>Rs. {Math.round(upiAmount)}</Text>
                    </View>}
                    {cash > 0 && <View style={{ ...styles.row }} wrap={false}>
                        <Text style={{ ...styles.col2, textAlign: "left" }}>
                            Cash
                        </Text>
                        <Text style={styles.col1}>
                            :
                        </Text>
                        <Text style={{ width: "80%", fontSize: 9 }}>Rs. {Math.round(cash)}</Text>
                    </View>}
                    <View style={{ ...styles.row, borderBottom: "1px solid #000", borderTop: "0.5px solid #000" }} wrap={false}>
                        <Text style={styles.col1}>

                        </Text>
                        <Text style={styles.col2}>Total Paid</Text>
                        <Text style={styles.col3}></Text>
                        <Text style={styles.col4}>

                        </Text>
                        <Text style={styles.col5}>Rs. {Math.round(totalPrice - totalDiscount - pointsDiscount)}</Text>
                    </View>

                    <View style={{ marginTop: 10, textAlign: "center", borderBottom: "0.5px solid #000", paddingBottom: 5 }}>
                        <Text style={{ fontSize: 10 }}>
                            We declare that this invoice shows the actual price of the goods described and that all particulars are true and correct.
                        </Text>
                    </View>

                    <View style={{ marginTop: 5 }}>
                        <Text style={{ fontSize: 10, textAlign: "center" }}>
                            Warranty Valid for 365 days from the date of Purchase, for Pasting & Stitching only
                        </Text>
                    </View>

                    <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                        <Text style={{ fontSize: 10, textAlign: "left" }}>
                            Thank You
                        </Text>
                        <Text style={{ fontSize: 10, textAlign: "right" }}>
                            Visit Again!!!
                        </Text>
                    </View>
                </View>
            </View>
        </Page>
    </Document>
);