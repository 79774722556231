// import React from 'react'

// const PromoBanner = () => {
//   return (
//       <div style={{ width: "100%", height: "40px", backgroundColor: "#000", color: "#fff", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center" }}>
//           <p>Sale is right here for you</p>
//       </div>
//   )
// }

// export default PromoBanner

import React from "react";
import Loader from "./Loader/Loader";
import firebase from "firebase";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { Link } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, A11y]);

export default class PromoBanner extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: "",
            link: null,
            loading: true
        };
    }

    componentDidMount() {
        firebase
            .firestore()
            .collection("settings")
            .onSnapshot((snap) => {
                snap.docChanges().forEach((changes) => {
                    this.setState({
                        text: changes.doc.data().promotionText,
                        link: changes.doc.data().promotionLink || null,
                        loading: false
                    });
                });
            });
    }

    render() {
        return (
            <div className="">
                {this.state.loading ? (
                    <Loader />
                ) : (
                         <Link to={this.state.link ?? null} style={{ width: "100%", height: "40px", backgroundColor: "#000", color: "#fff", fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center", padding: "0" }}>
          <p>{this.state.text}</p>
      </Link>
                )}
            </div>
        );
    }
}
