import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { Switch, Route, useLocation } from "react-router-dom";
import "./App.css";
import Navbar from "./Layout/Components/Navbar/Navbar";
import MiniNav from "./Layout/Components/MiniNav/MiniNav";
import Home from "./Layout/Pages/Home/Home";
import Footer from "./Layout/Components/Footer/Footer";
import Dashboard from "./Layout/Pages/Dashboard/Dashboard";
import CategoryList from "./Layout/Pages/Categories/CategoryList.page";
import ProductList from "./Layout/Pages/Products/ProductList/ProductList.page";
import ProductDesc from "./Layout/Pages/Products/ProductDescription/ProductDescription.page";
import ComingSoon from "./Layout/Components/ComingSoon/ComingSoon.component";
import NotFound from "./Layout/Pages/NotFound/NotFound.page";
import Order from "./Layout/Pages/Order/order";
import OrderInvoice from "./Layout/Pages/OrderInvoice/orderInvoice";
import Viewall from "./Layout/Pages/ViewAll/Viewall";
import NewArrival from "./Layout/Pages/NewArrival/NewArrival";
import Sale from "./Layout/Pages/Sale/Sale";
import SearchList from "./Layout/Pages/Search/SearchList.page";
import Cart from "./Layout/Pages/CheckOutCart/cart.page";
import Contact from "./Layout/Pages/Contact/Contact";
import Contact2 from "./Layout/Pages/Contact2/Contact2";
import Contact3 from "./Layout/Pages/Contact3/Contact3";
import Emboss from "./Layout/Pages/Emboss/Emboss";
import TrackOrder from "./Layout/Pages/TrackOrder/TrackOrder";
import Terms_Conditions from "./Layout/Pages/TermsConditions/TermsCondtions";
import ShippingPolicy from "./Layout/Pages/ShippingPolicy/ShippingPolicy";
import PromoBanner from "./Layout/Components/PromoBanner";
import BillDetail from "./Layout/Pages/BillDetails/BillDetail";
function App() {
	const location = useLocation();
	const child = React.createRef();
	const handleParent = () => {
		child.current.handleInit();
	};

	return (
		<div className="App">
			<PromoBanner />
			<Navbar ref={child} />
			<MiniNav />
			<AnimatePresence>
				<Switch location={location} key={location.pathname}>
					<Route exact path="/" component={Home} />
					<Route exact path="/Category/:id" component={CategoryList} />
					<Route exact path="/Category/:id1/:id2" component={ProductList} />
					<Route exact path="/Search/:id1" component={SearchList} />
					<Route exact path="/Category/:id1/:id2/:id3" render={(routeProps) => <ProductDesc handleParent={handleParent} {...routeProps} />} />
					<Route exact path="/Dashboard/:id" component={Dashboard} />
					<Route exact path="/ComingSoon" component={ComingSoon} />
					<Route exact path="/NewArrivals" component={NewArrival} />
					<Route exact path="/Cart/:id1/:id2/:id3/:id4/:id5" render={(routeProps) => <Cart handleParent={handleParent} {...routeProps} />} />
					<Route exact path="/Orders/:id" component={Order} />
					<Route exact path="/OrderInvoice/:id" component={OrderInvoice} />
					<Route exact path="/Sale" component={Sale} />
					<Route exact path="/Products/:id" component={Viewall} />
					<Route exact path="/Contact" component={Contact} />
					<Route exact path="/Emboss" component={Emboss} />
					<Route exact path="/CorporateContact" component={Contact2} />
					<Route exact path="/FranchiseContact" component={Contact3} />
					<Route exact path="/TrackOrder" component={TrackOrder} />
					<Route exact path="/termsandcondition" component={Terms_Conditions} />
					<Route exact path="/shippingpolicy" component={ShippingPolicy} />
					<Route exact path="/bill" component={BillDetail} />
					<Route exact path="*" component={NotFound} />
				</Switch>
			</AnimatePresence>
			<Footer />
		</div>
	);
}

export default App;
