import React from "react";
import "./Login.css";
import logo from "../../../assets/image_1.png";
import marfit from "../../../assets/marfit-label.png";
import google from "../../../assets/google.png";
import fb from "../../../assets/fb.png";
import loginBag from "../../../assets/login bag.jpg";
import firebase from "firebase";
import { signInWithGoogle } from "../../../config/firebaseConfig";
import loading from "../../../assets/loading.json";
import Lottie from "lottie-react-web";
import toaster from "toasted-notes";
import "toasted-notes/src/styles.css";
import axios from "axios";
import link from "../../../fetchPath";
import "react-phone-input-2/lib/material.css";
import PhoneInput from "react-phone-input-2";

const otpGenerator = require("otp-generator");

export default class Login extends React.Component {
	constructor(props) {
		super(props);
		for (var i = 1; i <= 6; i++) {
			this["c" + i] = React.createRef();
		}
		this.state = {
			toggle: "login",
			checked: false,
			username: "",
			email: "",
			password: "",
			loading: false,
			forgotpass: false,
			resetEmail: "",
			referal: "",
			showNext: false,
			otp: 0,
			showOTP: false,
			showPassword: false,
			c1: "",
			c2: "",
			c3: "",
			c4: "",
			c5: "",
			c6: "",
			phone: "",
			inputType: "default",
			referredBy: []
		};
	}

	componentDidMount() {
		var input = document.getElementById("input1");
		input.focus();
		// Execute a function when the user releases a key on the keyboard
		input.addEventListener("keyup", this.handleKeyEvent);
	}

	handleKeyEvent = (event) => {
		if (event.keyCode === 13) {
			// Cancel the default action, if needed
			event.preventDefault();
			this.handleNextLogin();
		}
	};

	handleKeyEvent2 = (event) => {
		if (event.keyCode === 13) {
			// Cancel the default action, if needed
			event.preventDefault();
			this.handleLogin();
		}
	};

	handleCheck = () => {
		this.setState({
			checked: !this.state.checked
		});
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		if (name === "phone") {
			if (value.length >= 0) {
				if (isNaN(value) === false) {
					this.setState({ [name]: value, inputType: "Phone" });
				} 
				else {
					this.setState({ [name]: value, inputType: "default" });
				}
			} else if (value.length === 0) {
				var input = document.getElementById("input1");
				var input2 = document.getElementById("input2");
				input2.removeEventListener("keyup", this.handleKeyEvent);
				this.setState(
					{
						showPassword: !true,
						showNext: !true,
						[name]: value
					},
					() => {
						input.addEventListener("keyup", this.handleKeyEvent);
					}
				);
			} else {
				this.setState({ [name]: value, inputType: "default" });
			}
		} else {
			this.setState({ [name]: value });
		}
	};

	handleRegister = () => {
		this.setState({
			loadingNext: true
		});
		firebase
			.auth()
			.createUserWithEmailAndPassword(this.state.phone, this.state.password)
			.then(async (res) => {
				var user = res.user;
				var res2 = await this.handleEmailRegister(user);
				var referal = res2.id.substr(16, 4) + this.state.username.substr(0, 2);
				firebase
					.firestore()
					.collection("users")
					.doc(res2.id)
					.update({
						referalID: referal
					})
					.then(() => {
						this.setState({
							loadingNext: false,
							showNext: false,
							showOTP: false
						});
						this.props.login(true);
						this.props.close(false);
					});
			});
	};

	setUpRecaptcha = () => {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container", {
			size: "invisible",
			callback: function (response) {
				console.log("Captcha Resolved");
				this.onSignInSubmit();
			},
			defaultCountry: "IN"
		});
	};

	onSignInSubmit = () => {
		this.setState({
			loadingNext: true
		});
		this.setUpRecaptcha();
		let phoneNumber = "+91" + this.state.phone;
		console.log("486", phoneNumber);
		let appVerifier = window.recaptchaVerifier;
		firebase
			.auth()
			.signInWithPhoneNumber(phoneNumber, appVerifier)
			.then((confirmationResult) => {
				// SMS sent. Prompt user to type the code from the message, then sign the
				// user in with confirmationResult.confirm(code).
				this.setState({
					showOTP: true,
					loadingNext: false,
					showNext: true
				});
				this.c1.current.focus();
				window.confirmationResult = confirmationResult;
				// console.log(confirmationResult);
				toaster.notify("OTP is sent");
			})
			.catch((error) => {
				console.log(error);
				toaster.notify(error.message);
				this.setState({
					loadingNext: false
				});
			});
	};

	onSubmitOtp = () => {
		this.setState({
			loadingNext: true
		});
		let phoneNumber = "+91" + this.state.phone;
		let otpInput = this.state.c1 + this.state.c2 + this.state.c3 + this.state.c4 + this.state.c5 + this.state.c6;
		let optConfirm = window.confirmationResult;
		// console.log(codee);
		optConfirm
			.confirm(otpInput)
			.then(async (result) => {
				var user = result.user;
				this.setState({
					user
				});
				var snaps = await firebase.firestore().collection("users").where("phone", "==", phoneNumber).get();
				console.log(snaps.size);
				if (snaps.size > 0) {
					this.setState({
						loadingNext: false,
						showNext: false,
						showOTP: false
					});
					this.props.login(true);
					this.props.close(false);
				} else {
					var res = await this.handlePhoneRegister(user);
					var referal = res.id.substr(16, 4) + this.state.username.substr(0, 2);
					firebase
						.firestore()
						.collection("users")
						.doc(res.id)
						.update({
							referalID: referal
						})
						.then(() => {
							this.setState({
								loadingNext: false,
								showNext: false,
								showOTP: false
							});
							this.props.login(true);
							this.props.close(false);
						});
				}
			})
			.catch((error) => {
				console.log(error);
				this.setState({
					loadingNext: false
				});
				toaster.notify("Incorrect OTP");
			});
	};

	handlePhoneRegister = async (user) => {
		let phoneNumber = "+91" + this.state.phone;
		var data;
		if (this.state.referal.length > 0) {
			data = await firebase
				.firestore()
				.collection("users")
				.add({
					email: "",
					name: this.state.username,
					orders: [],
					addresses: [],
					phone: phoneNumber,
					dob: "",
					gender: "",
					alt: "",
					cart: [],
					wishlist: [],
					referalID: "",
					points: 100,
					uid: user.uid,
					couponsUsed: [],
					pointsHistory: [
						{
							points: +100,
							reason: "REFFERAL SIGNUP BONUS",
							date: new Date()
						}
					],
					loginMethod: "Phone",
					referredBy: this.state.referredBy,
					date: new Date()
				});
		} else {
			data = await firebase
				.firestore()
				.collection("users")
				.add({
					email: "",
					name: this.state.username,
					orders: [],
					addresses: [],
					phone: phoneNumber,
					dob: "",
					gender: "",
					alt: "",
					cart: [],
					wishlist: [],
					referalID: "",
					points: 50,
					uid: user.uid,
					couponsUsed: [],
					pointsHistory: [
						{
							points: +50,
							reason: "SIGNUP BONUS",
							date: new Date()
						}
					],
					loginMethod: "Phone",
					referredBy: this.state.referredBy,
					date: new Date()
				});
		}
		return data;
	};

	handleEmailRegister = async (user) => {
		var data;
		if (this.state.referal.length > 0) {
			data = await firebase
				.firestore()
				.collection("users")
				.add({
					email: user.email,
					name: this.state.username,
					orders: [],
					addresses: [],
					phone: "",
					dob: "",
					gender: "",
					alt: "",
					cart: [],
					wishlist: [],
					referalID: "",
					points: 100,
					uid: user.uid,
					couponsUsed: [],
					pointsHistory: [
						{
							points: +100,
							reason: "REFFERAL SIGNUP BONUS",
							date: new Date()
						}
					],
					loginMethod: "Email",
					referredBy: this.state.referredBy,
					date: new Date()
				});
		} else {
			data = await firebase
				.firestore()
				.collection("users")
				.add({
					email: user.email,
					name: this.state.username,
					orders: [],
					addresses: [],
					phone: "",
					dob: "",
					gender: "",
					alt: "",
					cart: [],
					wishlist: [],
					referalID: "",
					points: 50,
					uid: user.uid,
					couponsUsed: [],
					pointsHistory: [
						{
							points: +50,
							reason: "SIGNUP BONUS",
							date: new Date()
						}
					],
					loginMethod: "Email",
					referredBy: this.state.referredBy,
					date: new Date()
				});
		}
		return data;
	};

	handleGoogleLogin = () => {
		var props = this.props;
		var provider = new firebase.auth.GoogleAuthProvider();
		provider.setCustomParameters({
			login_hint: "user@example.com"
		});
		this.setState({
			loadingNext: false,
			showNext: false,
			showOTP: false
		});
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(function (result) {
				// This gives you a Google Access Token. You can use it to access the Google API.
				var token = result.credential.accessToken;
				// The signed-in user info.
				var user = result.user;
				firebase
					.firestore()
					.collection("users")
					.where("email", "==", user.email)
					.get()
					.then((snap) => {
						if (snap.size === 0) {
							firebase
								.firestore()
								.collection("users")
								.add({
									email: user.email,
									name: user.displayName,
									orders: [],
									addresses: [],
									phone: "",
									dob: "",
									gender: "",
									alt: "",
									cart: [],
									wishlist: [],
									referalID: "",
									points: 50,
									uid: user.uid,
									couponsUsed: [],
									pointsHistory: [
										{
											points: +50,
											reason: "SIGNUP BONUS",
											date: new Date()
										}
									],
									loginMethod: "Email",
									referredBy: [],
									date: new Date()
								})
								.then((res) => {
									var referal = res.id.substr(16, 4) + user.displayName.substr(0, 2);
									firebase
										.firestore()
										.collection("users")
										.doc(res.id)
										.update({
											referalID: referal
										})
										.then(() => {
											props.login(true);
											props.close(false);
										});
								})
								.catch((err) => {
									toaster.notify(err.message);
									firebase.auth().signOut();
								});
						} else {
							props.login(true);
							props.close(false);
						}
					});
				// ...
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	handleFacebookLogin = () => {
		var props = this.props;
		var provider = new firebase.auth.GoogleAuthProvider();
		provider.setCustomParameters({
			login_hint: "user@example.com"
		});
		this.setState({
			loadingNext: false,
			showNext: false,
			showOTP: false
		});
		firebase
			.auth()
			.signInWithPopup(provider)
			.then(function (result) {
				// This gives you a Google Access Token. You can use it to access the Google API.
				var token = result.credential.accessToken;
				// The signed-in user info.
				var user = result.user;
				firebase
					.firestore()
					.collection("users")
					.where("email", "==", user.email)
					.get()
					.then((snap) => {
						if (snap.size === 0) {
							firebase
								.firestore()
								.collection("users")
								.add({
									email: user.email,
									name: user.displayName,
									orders: [],
									addresses: [],
									phone: "",
									dob: "",
									gender: "",
									alt: "",
									cart: [],
									wishlist: [],
									referalID: "",
									points: 50,
									uid: user.uid,
									couponsUsed: [],
									pointsHistory: [
										{
											points: +50,
											reason: "SIGNUP BONUS",
											date: new Date()
										}
									],
									loginMethod: "Email",
									referredBy: [],
									date: new Date()
								})
								.then((res) => {
									var referal = res.id.substr(16, 4) + user.displayName.substr(0, 2);
									firebase
										.firestore()
										.collection("users")
										.doc(res.id)
										.update({
											referalID: referal
										})
										.then(() => {
											props.login(true);
											props.close(false);
										});
								})
								.catch((err) => {
									toaster.notify(err.message);
									firebase.auth().signOut();
								});
						} else {
							props.login(true);
							props.close(false);
						}
					});
				// ...
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	handleChangeCode = (e) => {
		var { id, value } = e.target;
		this.setState(
			{
				[id]: value
			},
			() => {
				console.log(id);
				var num = parseInt(id[1]) + 1;
				var num2 = parseInt(id[1]) - 1;
				var num3 = parseInt(id[1]);
				if (num < 7 && value && num3 !== 6) {
					this["c" + num].current.focus();
				} else if (value === "" && num2 > 0) {
					this["c" + num2].current.focus();
				} else if (num3 === 6) {
					this.onSubmitOtp();
				}
			}
		);
	};

	handleNextLogin = async () => {
		this.setState({
			loadingNext: true
		});
		if (this.state.inputType === "Phone") {
			var snap = await firebase
				.firestore()
				.collection("users")
				.where("phone", "==", "+91" + this.state.phone)
				.get();
			if (snap.size > 0) {
				this.setState({
					loadingNext: false
				});
				this.onSignInSubmit();
			} else {
				this.setState({
					loadingNext: false,
					toggle: "register"
				});
				toaster.notify("No account found! please register");
			}
		} else {
			if (this.state.phone.includes("@")) {
				var snap = await firebase.firestore().collection("users").where("email", "==", this.state.phone).get();
				if (snap.size > 0) {
					this.setState({
						loadingNext: false
					});
					this.setState(
						{
							showPassword: true,
							showNext: true
						},
						() => {
							var input = document.getElementById("input1");
							input.removeEventListener("keyup", this.handleKeyEvent);
							var input2 = document.getElementById("input2");
							input2.addEventListener("keyup", this.handleKeyEvent2);
							input2.focus();
						}
					);
				} else {
					toaster.notify("No account found! please register");
					this.setState({
						loadingNext: false,
						toggle: "register"
					});
				}
			} else {
				toaster.notify("Not a valid email!");
				this.setState({
					loadingNext: false
				});
			}
		}
	};

	handleNextRegister = async () => {
		if (this.state.username === "") {
			toaster.notify("Please enter a username");
		} else if (this.state.phone === false) {
			toaster.notify("Please enter an email or phone number!");
		} else if (this.state.checked === "") {
			toaster.notify("Please accept the terms");
		} else {
			this.setState({
				loadingNext: true
			});
			if (this.state.referal.length > 0) {
				var data = await firebase.firestore().collection("users").where("referalID", "==", this.state.referal).get();
				if (data.size > 0) {
					data.forEach((doc) => {
						var referredBy = {
							data: doc.data(),
							applied: false
						};
						this.setState(
							{
								referredBy: referredBy
							},
							async () => {
								if (this.state.inputType === "Phone") {
									var snap = await firebase
										.firestore()
										.collection("users")
										.where("phone", "==", "+91" + this.state.phone)
										.get();
									if (snap.size === 0) {
										this.onSignInSubmit();
									} else {
										toaster.notify("Account already exists!");
										this.setState({
											toggle: "login",
											loadingNext: false,
											showNext: false,
											showOTP: false
										});
									}
								} else {
									var snap = await firebase.firestore().collection("users").where("email", "==", this.state.phone).get();
									if (snap.size === 0) {
										this.setState({
											showPassword: true,
											showNext: true,
											loadingNext: false
										});
									} else {
										toaster.notify("Account already exists!");
										this.setState({
											toggle: "login",
											loadingNext: false,
											showNext: false,
											showOTP: false
										});
									}
								}
							}
						);
					});
				} else {
					toaster.notify("Invalid Referal Code");
					this.setState({
						loadingNext: false,
						showNext: false,
						showOTP: false
					});
				}
			} else {
				if (this.state.inputType === "Phone") {
					var snap = await firebase
						.firestore()
						.collection("users")
						.where("phone", "==", "+91" + this.state.phone)
						.get();
					if (snap.size === 0) {
						this.onSignInSubmit();
					} else {
						toaster.notify("Account already exists!");
						this.setState({
							toggle: "login",
							loadingNext: false,
							showNext: false,
							showOTP: false
						});
					}
				} else {
					var snap = await firebase.firestore().collection("users").where("email", "==", this.state.phone).get();
					if (snap.size === 0) {
						this.setState({
							showPassword: true,
							showNext: true,
							loadingNext: false
						});
					} else {
						toaster.notify("Account already exists!");
						this.setState({
							toggle: "login",
							loadingNext: false,
							showNext: false,
							showOTP: false
						});
					}
				}
			}
		}
	};

	handleLogin = () => {
		this.setState({
			loadingNext: true
		});
		firebase
			.auth()
			.signInWithEmailAndPassword(this.state.phone, this.state.password)
			.then((res) => {
				this.setState({
					loadingNext: false
				});
				this.props.login(true);
				this.props.close(false);
			})
			.catch((err) => {
				toaster.notify(err.message);
				this.setState({
					loadingNext: false
				});
			});
	};

	toggleToLogin = () => {
		this.setState(
			{
				toggle: "login"
			},
			() => {
				var input = document.getElementById("input1");
				input.focus();
				input.addEventListener("keyup", this.handleKeyEvent);
			}
		);
	};

	render() {
		var inpuCode = [];
		for (var i = 1; i <= 6; i++) {
			inpuCode.push("c" + i);
		}
		return (
			<div className="login-container">
				<div className="login">
					<i
						className="fa fa-times fa-1x"
						onClick={() => {
							firebase.auth().signOut();
							this.props.close(false);
							document.body.setAttribute("style", "");
							window.scrollTo(0, this.props.windowOffSet);
						}}></i>
					{this.state.toggle === "register" ? (
						<div className="register-form">
							<div className="marfit-img">
								<img src={logo} alt="Marfit logo" />
								<img src={marfit} alt="Marfit title" />
							</div>
							<div id="recaptcha-container"></div>
							{this.state.showOTP ? (
								<div className="otp-cont">
									<div className="vrf">
										<p>Enter 6 digit verification code send to your phone number</p>
									</div>
									<div className="verification-cont">
										{inpuCode.map((item) => {
											return (
												<div className="code-verification">
													<input maxLength={1} id={item} type="text" value={this.state[item]} onChange={this.handleChangeCode} name={item} ref={this[item]} />
												</div>
											);
										})}
									</div>
								</div>
							) : (
								<>
									<div className="input-fields">
										<input type="text" name="username" placeholder="Username" onChange={this.handleChange} maxLength={10} />
										<input ytpe="text" name="referal" placeholder="Referal code(optional)" onChange={this.handleChange} />
										<div className="phone-input">
											{this.state.inputType === "Phone" ? (
												<div className="phone-input-code">
													<p>+91</p>
												</div>
											) : null}
											<input className="email-input" placeholder="Enter Email/Mobile number" value={this.state.phone} onChange={this.handleChange} name="phone" />
										</div>
										{this.state.showPassword ? <input className="email-input" type="password" placeholder="Enter Password" value={this.state.password} onChange={this.handleChange} name="password" /> : null}
									</div>
									{this.state.showNext ? null : (
										<div className="agree">
											<input class="styled-checkbox" type="checkbox" name="checkbox" id="check" checked={this.state.checked} onChange={this.handleCheck} />
											<p className="conditions">
												I agree to the{" "}
												<a href="https://firebasestorage.googleapis.com/v0/b/marfit-ea7ba.appspot.com/o/doc%2Ftc.pdf?alt=media&token=0255c158-8e00-4e0e-9b95-f1a4fb7311c3" className="terms" target="_blank">
													TERMS & CONDITION
												</a>
												.
											</p>
										</div>
									)}
								</>
							)}
							{this.state.showNext ? (
								<>
									{this.state.loadingNext ? (
										<Lottie options={{ animationData: loading }} width={50} height={50} />
									) : (
										<>
											{this.state.showOTP ? (
												<button type="button" className="btn-next" onClick={this.onSubmitOtp}>
													Verify & Register
												</button>
											) : (
												<button className="btn-next" type="button" onClick={this.handleRegister}>
													Register
												</button>
											)}
										</>
									)}
								</>
							) : (
								<>
									{this.state.loadingNext ? (
										<Lottie options={{ animationData: loading }} width={50} height={50} />
									) : (
										<button className="btn-next" type="button" onClick={this.handleNextRegister}>
											Next
										</button>
									)}
								</>
							)}
							<p onClick={this.toggleToLogin} className="go-to-login">
								Existing User? Login
							</p>
						</div>
					) : (
						<div className="login-form">
							<div className="marfit-img">
								<img src={logo} alt="Marfit logo" />
								<img src={marfit} alt="Marfit title" />
							</div>
							<div id="recaptcha-container"></div>
							{this.state.showOTP ? (
								<div className="otp-cont">
									<div className="vrf">
										<p>Enter 6 digit verification code send to your phone number</p>
									</div>
									<div className="verification-cont">
										{inpuCode.map((item) => {
											return (
												<div className="code-verification">
													<input maxLength={1} id={item} type="text" value={this.state[item]} onChange={this.handleChangeCode} name={item} ref={this[item]} />
												</div>
											);
										})}
									</div>
								</div>
							) : (
								<div className="input-fields">
									<div className="phone-input">
										{this.state.inputType === "Phone" ? (
											<div className="phone-input-code">
												<p>+91</p>
											</div>
										) : null}
										<input className="email-input" id="input1" placeholder="Enter Email/Mobile number" value={this.state.phone} onChange={this.handleChange} name="phone" />
									</div>
									{this.state.showPassword ? <input className="email-input" id="input2" type="password" placeholder="Enter Password" value={this.state.password} onChange={this.handleChange} name="password" /> : null}
								</div>
							)}

							{this.state.showNext ? (
								<>
									{this.state.loadingNext ? (
										<Lottie options={{ animationData: loading }} width={50} height={50} />
									) : (
										<>
											{this.state.showOTP ? (
												<>
													{this.state.loadingNext ? (
														<Lottie options={{ animationData: loading }} width={50} height={50} />
													) : (
														<button type="button" className="btn-next" onClick={this.onSubmitOtp}>
															Verify & Login
														</button>
													)}
												</>
											) : (
												<>
													{this.state.loadingNext ? (
														<Lottie options={{ animationData: loading }} width={50} height={50} />
													) : (
														<button className="btn-next" type="button" onClick={this.handleLogin}>
															Login
														</button>
													)}
												</>
											)}
										</>
									)}
								</>
							) : (
								<>
									{this.state.loadingNext ? (
										<Lottie options={{ animationData: loading }} width={50} height={50} />
									) : (
										<button className="btn-next" type="button" onClick={this.handleNextLogin}>
											Next
										</button>
									)}
								</>
							)}
							<div className="lines">
								<div className="horizontal"></div>
								<div className="or">OR</div>
								<div className="horizontal"></div>
							</div>
							<div className="social">
								<img src={google} alt="Google Image" onClick={this.handleGoogleLogin} />
							</div>
							<p
								onClick={() => {
									this.setState({
										toggle: "register"
									});
								}}
								className="go-to-register">
								New to Marfit? Create an account
							</p>
						</div>
					)}
				</div>
			</div>
		);
	}
}
