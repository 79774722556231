import React from "react";
import "./OrderCard.css";
import moment from "moment";
import logo from "../../../assets/image_1.png";

const BillCard = (props) => {
    return (
        <div
            className='order-card'
            onClick={() => {
                window.location.href = "/Orders/" + props.order?.billNo;
            }}>
            <div className='product-section1'>
                <div className='product-image'>
                    <img src={props.item?.thumbnail || logo} alt='product-image' />
                </div>
                <div className='product-info'>
                    <p className='product-title'>
                        {props.item?.title}
                        <br />
                        {/* {props.item?.emboss ? " (with emboss: " + props.item?.embossText + ")" : null} */}
                    </p>
                    <p className='product-color'>Color : {props.item?.color}</p>
                    <p className='product-quantity'>Quantity : {props.item?.qty}</p>
                    {props.item?.size ? (
                        <p className='product-size'>
                            Size :<span> {props.item?.size}</span>
                        </p>
                    ) : null}
                </div>
            </div>
            <div className='product-section2'>
                <p>₹{props.item?.sp}</p>
            </div>
        </div>
    );
};

export default BillCard;
