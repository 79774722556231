import React, { Component } from "react";
import "./Terms_Conditions.css";

class Terms_Conditions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="terms_conditions-container">
        <div className="header">
          <h1>Terms and Condition</h1>
        </div>
        <div className="info">
          <div className="sub-header">
            <h3>Introduction</h3>
          </div>
          <div className="text">
            <p>
              Welcome to the marfit.in brought to you by MARFIT. In using the marfit.in service, of <b>MARFIT</b> you are deemed to have accepted the
              terms and conditions listed below. All products/services and information displayed on marfit.in constitute an 'invitation to offer'.
              Your order for purchase constitutes your 'offer' which shall be subject to the terms and conditions as listed below. marfit.in reserves
              the right to accept or reject your offer. If you have supplied us with your valid email address, we will notify you by email as soon as
              possible to confirm receipt of your order and email you again to confirm details and therefore process the order. Our acceptance of your
              order will take place upon dispatch of the product(s) ordered. No act or omission of marfit.in prior to the actual dispatch of the
              product(s) ordered will constitute acceptance of your offer.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Membership Eligibility:</h3>
          </div>
          <div className="text">
            <p>
              Use of the Site is available only to persons who can form legally binding contracts under applicable law. Persons who are 'incompetent
              to contract' within the meaning of the Indian Contract Act, 1872 including un-discharged insolvents etc. are not eligible to use the
              Site. If you are a minor i.e. under the age of 18 years but at least 13 years of age you may use this Site only under the supervision of
              a parent or legal guardian who agrees to be bound by these Terms of Use. If your age is below that of 18 years your parents or legal
              guardians can transact on behalf of you if they are registered users. You are prohibited from purchasing any material which is for adult
              consumption the sale or purchase of which to/by minors are strictly prohibited. marfit.in reserves the right to terminate your
              membership and refuse to provide you with access to the Site if marfit.in discovers that you are under the age of 18 years. The Site is
              not available to persons whose membership has been suspended or terminated by Marfit.in for any reason whatsoever. If you are
              registering as a business entity, you represent that you have the authority to bind the entity to this User Agreement. Unless otherwise
              specified, the materials on this website are directed solely at those who access this website from India. marfit.in makes no
              representation that any products or services referred to in the materials on this website are appropriate for use, or available outside
              India. Those who choose to access this Site from outside India are responsible for compliance with local laws if and to the extent local
              laws are applicable. marfit.in will deliver the products only within India and will not be liable for any claims relating to any
              products ordered from outside India. Except where additional terms and conditions are provided which are product specific, these terms
              and conditions supersede all previous representations, understandings, or agreements and shall prevail notwithstanding any variance with
              any other terms of any order submitted. By using the services of marfit.in you agree to be bound by the Terms and Conditions.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>User Identity and Password:</h3>
          </div>
          <div className="text">
            <p>
              If you use marfit.in you shall be responsible for maintaining the confidentiality of your User ID and Password and you shall be
              responsible for all activities that occur under your User ID and Password. You agree that if you provide any information that is untrue,
              inaccurate, not current or incomplete or marfit.in has reasonable grounds to suspect that such information is untrue, inaccurate, not
              current or incomplete, or not in accordance with the this Terms of Use, marfit.in has the right to indefinitely suspend or terminate or
              block access of your membership with marfit.in.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>You also agree to:</h3>
          </div>
          <div className="text">
            <p>
              1. Provide true, accurate, current and complete information about yourself as prompted by all online store’s registration form (such
              information being the 'Registration Data'). All the information which compulsorily needs to be filled are marked in asterix (*) sign. 2.
              Maintain and promptly update the Registration Data to keep it true, accurate, current and complete. If you provide any information that
              is untrue, inaccurate, incomplete, or not current or if marfit.in has reasonable grounds to suspect that such information is untrue,
              inaccurate, not current or not in accordance with the User Agreement, marfit.in has the right to indefinitely suspend or terminate your
              membership and refuse to provide you with access to the Site.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Pricing Information:</h3>
          </div>
          <div className="text">
            <p>
              While marfit.in strives to provide accurate product and pricing information, pricing or typographical errors may occur. marfit.in cannot
              confirm the price of a product until after you order. In the event that a product is listed at an incorrect price or with incorrect
              information due to an error in pricing or product information, Marfit.in shall have the right, at our sole discretion, to refuse or
              cancel any orders placed for that product, unless the product has already been dispatched. In the event that an item is mis-priced,
              marfit.in may, at its discretion, either contact you for instructions or cancel your order and notify you of such cancellation. Unless
              the product ordered by you has been dispatched, your offer will not be deemed accepted and marfit.in will have the right to modify the
              price of the product and contact you for further instructions using the e-mail address provided by you during the time of registration,
              or cancel the order and notify you of such cancellation. In the event that Marfit.in accepts your order the same shall be debited to
              your credit card account and duly notified to you by email that the payment has been processed. The payment may be processed prior to
              marfit.in dispatch of the product that you have ordered. If we have to cancel the order after we have processed the payment, the said
              amount will be reversed to your credit card account. However, sometimes a price online may not match the price in a store. In our effort
              to be the lowest price provider in your particular geographic region, store pricing will sometimes differ from online prices. Prices and
              availability are subject to change without notice.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Cancellation by marfit.in:</h3>
          </div>
          <div className="text">
            <p>
              Due to various fraudulent activities experienced with ecommerce websites, we ensure an additional security measure for valued customers
              like yourself. As a part of these additional security measures, we might request you to provide relevant supporting documents (Identity
              proof etc). The documents shared with us are safe and will remain undisclosed to any outside party. In case the requested documents are
              not shared with us, marfit.in reserves the right to cancel the order entirely with due intimation to the concerned person.marfit.in
              reserves the right to cancel any order with due intimation to the concerned person, under situations where marfit.in is not able to meet
              the requirement of the order placed. However, marfit.in will ensure that any communication of cancellation of an order, so cancelled, is
              intimated within appropriate time to the concerned person and any applicable refund, will be made in reasonable time (within 10 days).
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Cancellations by the Customer:</h3>
          </div>
          <div className="text">
            <p>
              In case we receive a cancellation notice and the order has not been processed/approved by us, we shall cancel the order and refund the
              entire amount. We will not be able to cancel orders that have already been processed and shipped out by us. Marfit.in has the full right
              to decide whether an order has been processed or not. The customer agrees not to dispute the decision made by Marfit.in and accept
              marfit.in's decision regarding the cancellation.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Return and Exchange</h3>
          </div>
          <div className="text">
            <p>1. Request for Return and Exchange of Unused, Resalable, Undamaged goods will be entertained in case:</p>
            <p className="sub-text">a. The customer wants a change in size.</p>
            <p className="sub-text">
              b. The customer wants to exchange the product so receives with any other product on the website subject to payment of the difference in
              price
            </p>
            <p className="sub-text">c. The customer wants a change in colour.</p>
            <p className="sub-text">d. The customer has received the wrong product.</p>
            <p>2. Exchange of product is allowed only once after a purchase</p>
            <p>3. Money shall not be refunded under any circumstances.</p>
            <p>4. Convenience charge for Pickup and further Delivery will have to be paid by customer.</p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Return of Items with Major Manufacturing Defects:</h3>
          </div>
          <div className="text">
            <p>
              1. We provide 100% replacement for major manufacturing defects on all leather products within the warranty period. Major manufacturing
              defects include:
            </p>
            <p className="sub-text">a. Damaged Upper Material</p>
            <p className="sub-text">b. Damaged Stitching and Pasting</p>
            <p className="sub-text">c. No claims on damage on ornamental fittings</p>
            <p>2. Replacement will be processed through the same online mode in 15 working days.</p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Return or Replacement Procedure:</h3>
          </div>
          <div className="text">
            <p>To return or replace of any product, the customer shall go through the procedure as follows:</p>
            <p>a. The customer must contact through the website within 15 days of receiving the item to register a request.</p>
            <p>b. The customer must send the clear picture of the product with manufacturing defects and the proper reasoning.</p>
            <p>c. The customer can expect a reverse pickup within 7 days after a complaint has been lodged</p>
            <p>d. The customer must produce the original bill and return the product to the courier company representative.</p>
            <p>
              e. Products must be returned in their original packing box in an unused, undamaged, unwashed condition. No stains or discolouration will
              be accepted.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Warranty:</h3>
          </div>
          <div className="text">
            <p>
              marfit.in gives 1 year warranty on all leather products for stitching, pasting and on zipper, sold online. Within warranty period the
              item with these defects can be replaced. For any other type of defect you need to contact us.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>DO’s and DON’Ts:</h3>
          </div>
          <div className="text">
            <p className="sub-text">a. Keep the products protected from water and sunlight.</p>
            <p className="sub-text">b. Brush your leather down with a damp cloth at least once a week.</p>
            <p className="sub-text">
              c. Do not use any soaps or cleansers. These types of chemicals can remove the natural oils in the leather used to preserve the material.
              Chemical soaps will cause the leather to dry out, crack, and begin to deteriorate. Stick with water.
            </p>
            <p className="sub-text">
              d. Do not put your leather by a fire, out in the sun, or near a heater to dry it off. Dry your leather in room temperature by letting it
              sit.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Credit Card Details:</h3>
          </div>
          <div className="text">
            <p>
              You agree, understand and confirm that the credit card details provided by you for availing of services on marfit.in will be correct and
              accurate and you shall not use the credit card which is not lawfully owned by you, i.e. in a credit card transaction, you must use your
              own credit card. You further agree and undertake to provide the correct and valid credit card details to marfit.in. Further the said
              information will not be utilised and shared by marfit.in with any of the third parties unless required for fraud verifications or by
              law, regulation or court order. Marfit.in will not be liable for any credit card fraud. The liability for use of a card fraudulently
              will be on you and the onus to 'prove otherwise' shall be exclusively on you.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Fraudulent /Declined Transactions:</h3>
          </div>
          <div className="text">
            <p>
              marfit.in reserves the right to recover the cost of goods, collection charges and lawyers fees from persons using the Site fraudulently.
              marfit.in reserves the right to initiate legal proceedings against such persons for fraudulent use of the Site and any other unlawful
              acts or acts or omissions in breach of these terms and conditions.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Electronic Communications:</h3>
          </div>
          <div className="text">
            <p>
              When you visit the Site or send emails to us, you are communicating with us electronically. You consent to receive communications from
              us electronically. We will communicate with you by email or by posting notices on the Site. You agree that all agreements, notices,
              disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in
              writing.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Terms and conditions for Customer Support Chat:</h3>
          </div>
          <div className="text">
            <p>1. marfit.in may suspend the chat service at any time without notice</p>
            <p>2. marfit.in or its executives are not responsible for any delay caused in attending to or replying to the queries via chat.</p>
            <p>
              3. Communication through chat would be stored by marfit.in for future reference; customer would not have the right to access this
              information at a later date.
            </p>
            <p>
              4. While chatting you may not put on any objectionable information i.e. unlawful, threatening, abusive, defamatory, obscene information.
            </p>
            <p>
              5. The chat room will not be used for selling of any products or advising on business opportunity or any other form of solicitation.
            </p>
            <p>6. Powered by Comm100.</p>
            <p>7. You may proceed further, and chat with our online customer care executive only if you agree to the above terms and conditions</p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>You Agree and Confirm:</h3>
          </div>
          <div className="text">
            <p>
              1. That in the event that a non-delivery occurs on account of a mistake by you (i.e. wrong name or address or any other wrong
              information) any extra cost incurred by marfit.in for redelivery shall be claimed from you.
            </p>
            <p>
              2. That you will use the services provided by marfit.in its affiliates, consultants and contracted companies, for lawful purposes only
              and comply with all applicable laws and regulations while using the Site and transacting on the Site.
            </p>
            <p>
              3. You will provide authentic and true information in all instances where such information is requested of you. marfit.in reserves the
              right to confirm and validate the information and other details provided by you at any point of time. If upon confirmation your details
              are found not to be true (wholly or partly), marfit.in has the right in its sole discretion to reject the registration and debar you
              from using the Services of marfit.in and / or other affiliated websites without prior intimation whatsoever.
            </p>
            <p>
              4.That you are accessing the services available on this Site and transacting at your sole risk and are using your best and prudent
              judgment before entering into any transaction through this Site
            </p>
            <p>5. That the address at which delivery of the product ordered by you is to be made will be correct and proper in all respects.</p>
            <p>
              6. That before placing an order you will check the product description carefully. By placing an order for a product you agree to be
              bound by the conditions of sale included in the item's description.
            </p>
            <p>7. To receive promotional mailers and sms.</p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>You may not use the Site for any of the following purposes:</h3>
          </div>
          <div className="text">
            <p>
              1. Disseminating any unlawful, harassing, libelous, abusive, threatening, harmful, vulgar, obscene, or otherwise objectionable material.
            </p>
            <p>
              2. Transmitting material that encourages conduct that constitutes a criminal offence, results in civil liability or otherwise breaches
              any relevant laws, regulations or code of practice.
            </p>
            <p>3. Gaining unauthorised access to other computer systems.</p>
            <p>4. Interfering with any other person's use or enjoyment of the Site.</p>
            <p>5. Breaching any applicable laws;</p>
            <p>6. Interfering or disrupting networks or web sites connected to the Site.</p>
            <p>
              7. Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner. marfit.in
              reserves full right to take appropriate action against you, for violating the above mentioned rules and regulations.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Colours:</h3>
          </div>
          <div className="text">
            <p>
              We have made every effort to display the colours of the products that appear on the Site as accurately as possible. However, as the
              actual colours you see will depend on your monitor, we cannot guarantee that your monitor's display of any colours will be accurate.
              Images We strive hard to get the real image of every product listed, but it should be considered indicative only.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Modification of Terms & Conditions of Service:</h3>
          </div>
          <div className="text">
            <p>
              marfit.in may at any time update the Terms & Conditions of Use of the site without any prior notification to you. You can access the
              latest version of the User Agreement at any given time on marfit.in. You should regularly review the Terms & Conditions on marfit.in. In
              the event the modified Terms & Conditions is not acceptable to you, you should discontinue using the service. However, if you continue
              to use the service you shall be deemed to have agreed to accept and abide by the modified Terms & Conditions of Use of this site.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Governing Law and Jurisdiction:</h3>
          </div>
          <div className="text">
            <p>
              This User Agreement shall be construed in accordance with the applicable laws of India. The Courts at Mumbai shall have exclusive
              jurisdiction in any proceedings arising out of this agreement.Any dispute or difference either in interpretation or otherwise, of any
              terms of this User Agreement between the parties hereto, the same shall be referred to an independent arbitrator who will be appointed
              by <b>MARFIT</b> and his decision shall be final and binding on the parties hereto. The above arbitration shall be in accordance with
              the Arbitration and Conciliation Act, 1996 as amended from time to time. The arbitration shall be held in Kolkata. The language of
              Arbitration shall be English. The High Court of judicature at Calcutta alone shall have the jurisdiction and the Laws of India shall
              apply
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Reviews, Feedback, Submissions:</h3>
          </div>
          <div className="text">
            <p>
              All reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted or offered to marfit.in on or
              by this Site or otherwise disclosed, submitted or offered in connection with your use of this Site (collectively, the 'Comments') shall
              be and remain marfit.in property. Such disclosure, submission or offer of any Comments shall constitute an assignment to marfit.in of
              all worldwide rights, titles and interests in all copyrights and other intellectual properties in the Comments. Thus, marfit.in owns
              exclusively all such rights, titles and interests and shall not be limited in any way in its use, commercial or otherwise, of any
              Comments. marfit.in will be entitled to use, reproduce, disclose, modify, adapt, create derivative works from, publish, display and
              distribute any Comments you submit for any purpose whatsoever, without restriction and without compensating you in any way. marfit.in is
              and shall be under no obligation (1) to maintain any Comments in confidence; (2) to pay you any compensation for any Comments; or (3) to
              respond to any Comments. You agree that any Comments submitted by you to the Site will not violate this policy or any right of any third
              party, including copyright, trademark, privacy or other personal or proprietary right(s), and will not cause injury to any person or
              entity. You further agree that no Comments submitted by you to the Site will be or contain libelous or otherwise unlawful, threatening,
              abusive or obscene material, or contain software viruses, political campaigning, commercial solicitation, chain letters, mass mailings
              or any form of 'spam' marfit.in does not regularly review posted Comments, but does reserve the right (but not the obligation) to
              monitor and edit or remove any Comments submitted to the Site. You grant marfit.in the right to use the name that you submit in
              connection with any Comments. You agree not to use a false email address, impersonate any person or entity, or otherwise mislead as to
              the origin of any Comments you submit. You are and shall remain solely responsible for the content of any Comments you make and you
              agree to indemnify marfit.in and its affiliates for all claims resulting from any Comments you submit. marfit.in and its affiliates take
              no responsibility and assume no liability for any Comments submitted by you or any third party.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Copyright & Trademark:</h3>
          </div>
          <div className="text">
            <p>
              marfit.in and its suppliers and licensors expressly reserve all intellectual property rights in all text, programs, products, processes,
              technology, content and other materials, which appear on this Site. Access to this Site does not confer and shall not be considered as
              conferring upon anyone any license under any of marfit.in or any third party's intellectual property rights. All rights, including
              copyright, in this website are owned by or licensed to Marfit.in. Any use of this website or its contents, including copying or storing
              it or them in whole or part, other than for your own personal, non-commercial use is prohibited without the permission of marfit.in. You
              may not modify, distribute or re-post anything on this website for any purpose. The marfit.in names and logos and all related product
              and service names, design marks and slogans are the trademarks or service marks of <b>MARFIT</b>. All other marks are the property of
              their respective companies. No trademark or service mark license is granted in connection with the materials contained on this Site.
              Access to this Site does not authorize anyone to use any name, logo or mark in any manner. References on this Site to any names, marks,
              products or services of third parties or hypertext links to third party sites or information are provided solely as a convenience to you
              and do not in any way constitute or imply marfit.in endorsement, sponsorship or recommendation of the third party, information, product
              or service. marfit.in is not responsible for the content of any third party sites and does not make any representations regarding the
              content or accuracy of material on such sites. If you decide to link to any such third party websites, you do so entirely at your own
              risk. All materials, including images, text, illustrations, designs, icons, photographs, programs, music clips or downloads, video clips
              and written and other materials that are part of this Site (collectively, the 'Contents') are intended solely for personal,
              non-commercial use. You may download or copy the Contents and other downloadable materials displayed on the Site for your personal use
              only. No right, title or interest in any downloaded materials or software is transferred to you as a result of any such downloading or
              copying. You may not reproduce (except as noted above), publish, transmit, distribute, display, modify, create derivative works from,
              sell or participate in any sale of or exploit in any way, in whole or in part, any of the Contents, the Site or any related software.
              All software used on this Site is the property of marfit.in or its suppliers and protected by Indian and international copyright laws.
              The Contents and software on this Site may be used only as a shopping resource. Any other use, including the reproduction, modification,
              distribution, transmission, republication, display, or performance, of the Contents on this Site is strictly prohibited. Unless
              otherwise noted, all Contents are copyrights, trademarks, trade dress and/or other intellectual property owned, controlled or licensed
              by marfit.in ,one of its affiliates or by third parties who have licensed their materials to marfit.in and are protected by Indian and
              international copyright laws. The compilation (meaning the collection, arrangement, and assembly) of all Contents on this Site is the
              exclusive property of Marfit.in and is also protected by Indian and International copyright laws.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Objectionable Material:</h3>
          </div>
          <div className="text">
            <p>
              You understand that by using this Site or any services provided on the Site, you may encounter Content that may be deemed by some to be
              offensive, indecent, or objectionable, which Content may or may not be identified as such. You agree to use the Site and any service at
              your sole risk and that to the fullest extent permitted under applicable law, marfit.in and its affiliates shall have no liability to
              you for Content that may be deemed offensive, indecent, or objectionable to you.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Indemnity:</h3>
          </div>
          <div className="text">
            <p>
              You agree to defend, indemnify and hold harmless marfit.in, its employees, directors, officers, agents and their successors and assigns
              from and against any and all claims, liabilities, damages, losses, costs and expenses, including attorney's fees, caused by or arising
              out of claims based upon your actions or inactions, which may result in any loss or liability to marfit.in or any third party including
              but not limited to breach of any warranties, representations or undertakings or in relation to the non-fulfillment of any of your
              obligations under this User Agreement or arising out of your violation of any applicable laws, regulations including but not limited to
              Intellectual Property Rights, payment of statutory dues and taxes, claim of libel, defamation, violation of rights of privacy or
              publicity, loss of service by other subscribers and infringement of intellectual property or other rights. This clause shall survive the
              expiry or termination of this User Agreement.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Termination:</h3>
          </div>
          <div className="text">
            <p>
              This User Agreement is effective unless and until terminated by either you or marfit.in. You may terminate this User Agreement at any
              time, provided that you discontinue any further use of this Site. marfit.in may terminate this User Agreement at any time and may do so
              immediately without notice, and accordingly deny you access to the Site, Such termination will be without any liability to marfit.in.
              Upon any termination of the User Agreement by either you or marfit.in you must promptly destroy all materials downloaded or otherwise
              obtained from this Site, as well as all copies of such materials, whether made under the User Agreement or otherwise. marfit.in's right
              to any Comments shall survive any termination of this User Agreement. Any such termination of the User Agreement shall not cancel your
              obligation to pay for the product already ordered from the Site or affect any liability that may have arisen under the User Agreement.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Limitation of Liability and Disclaimers:</h3>
          </div>
          <div className="text">
            <p>
              The Site is provided without any warranties or guarantees and in an 'As Is' condition. You must bear the risks associated with the use
              of the Site. The Site provides content from other Internet sites or resources and while marfit.in tries to ensure that material included
              on the Site is correct, reputable and of high quality, it cannot accept responsibility if this is not the case. marfit.in will not be
              responsible for any errors or omissions or for the results obtained from the use of such information or for any technical problems you
              may experience with the Site. This disclaimer does not apply to any product warranty offered by the manufacturer of the product as
              specified in the product specifications. This disclaimer constitutes an essential part of this User Agreement. To the fullest extent
              permitted under applicable law, marfit.in or its suppliers shall not be liable for any indirect, incidental, special, incidental,
              consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible
              losses arising out of or in connection with the Site, its services or this User Agreement. Without prejudice to the generality of the
              section above, the total liability of marfit.in to you for all liabilities arising out of this User Agreement be it in tort or contract
              is limited to the value of the product ordered by you. marfit.in, its associates and technology partners make no representations or
              warranties about the accuracy, reliability, completeness, correctness and/or timeliness of any content, information, software, text,
              graphics, links or communications provided on or through the use of the Site or that the operation of the Site will be error free and/or
              uninterrupted. Consequently, marfit.in assumes no liability whatsoever for any monetary or other damage suffered by you on account of
              the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Site; and/or
              any interruption or errors in the operation of the Site.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Site Security:</h3>
          </div>
          <div className="text">
            <p>
              You are prohibited from violating or attempting to violate the security of the Site, including, without limitation, (a) accessing data
              not intended for you or logging onto a server or an account which you are not authorized to access; (b) attempting to probe, scan or
              test the vulnerability of a system or network or to breach security or authentication measures without proper authorization; (c)
              attempting to interfere with service to any other user, host or network, including, without limitation, via means of submitting a virus
              to the Site, overloading, 'flooding,' 'spamming,' 'mailbombing' or 'crashing;' (d) sending unsolicited email, including promotions
              and/or advertising of products or services; or (e) forging any TCP/IP packet header or any part of the header information in any email
              or newsgroup posting. Violations of system or network security may result in civil or criminal liability. marfit.in will investigate
              occurrences that may involve such violations and may involve, and cooperate with, law enforcement authorities in prosecuting users who
              are involved in such violations. You agree not to use any device, software or routine to interfere or attempt to interfere with the
              proper working of this Site or any activity being conducted on this Site. You agree, further, not to use or attempt to use any engine,
              software, tool, agent or other device or mechanism (including without limitation browsers, spiders, robots, avatars or intelligent
              agents) to navigate or search this Site other than the search engine and search agents available from marfit.in on this Site and other
              than generally available third party web browsers (e.g., Netscape Navigator, Microsoft Explorer). Entire Agreement If any part of this
              agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty
              disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed to be superseded by a
              valid, enforceable provision that most closely matches the intent of the original provision and the remainder of the agreement shall
              continue to be in effect. Unless otherwise specified herein, this agreement constitutes the entire agreement between you and marfit.in
              with respect to the Marfit.in sites/services and it supersedes all prior or contemporaneous communications and proposals, whether
              electronic, oral or written, between you and marfit.in with respect to the marfit.in sites/services. marfit.in's failure to act with
              respect to a breach by you or others does not waive its right to act with respect to subsequent or similar breaches.
            </p>
          </div>
        </div>
        <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Privacy Policy:</h3>
          </div>
          <div className="text">
            <p>
              We do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent and we only
              use your information as described in the Privacy Policy. We view protection of your privacy as a very important community principle. We
              understand clearly that you and Your Information is one of our most important assets. We store and process Your Information on computers
              located in the India that are protected by physical as well as technological security devices. We use third parties to verify and
              certify our privacy principles. If you object to your Information being transferred or used in this way please do not use the Site.
            </p>
          </div>
        </div>
        {/* <div className="info">
          <div className="sub-header sub-header-1">
            <h3>Membership Eligibility:</h3>
          </div>
          <div className="text">
            <p></p>
          </div>
        </div> */}
      </div>
    );
  }
}

export default Terms_Conditions;
