import React, { Fragment } from "react";
import "./Dashboard.css";
import { motion } from "framer-motion";
import firebase from "firebase";
import Lottie from "lottie-react-web";
import loading from "../../../assets/loading.json";
import toaster from "toasted-notes";
import empty from "./629-empty-box.json";
import emptywish from "./10000-empty-box.json";
import Card from "../../Components/Card/Card";
import { CopyToClipboard } from "react-copy-to-clipboard";
import refer from "../../../assets/refer.json";
import redeem from "./8287-redeem-points-to-get-gift.json";
import OrdersComp from "../../Components/OrdersComp/OrdersComp";
import circular from "../../../assets/circular loading.json";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import OrderCard from "../../Components/OrderCard/OrderCard";
import BillCard from "../../Components/OrderCard/BillCard";

const pageVariants = {
	initial: {
		opacity: 0,
		x: "-100vw"
	},
	in: {
		opacity: 1,
		x: 0
	},
	out: {
		opacity: 0,
		x: 0
	}
};

const pageTransition = {
	type: "spring",
	damping: 20,
	stiffness: 100
};

class Dashboard extends React.Component {
	constructor(props) {
		super();
		this.state = {
			tab: props.match.params.id,
			currentUser: [],
			editProifle: false,
			name: "",
			dob: "",
			gender: "",
			phone: "",
			circular: false,
			alt: "",
			editProifleLoading: false,
			orders: [],
			bills: [],
			addresses: [],
			loading: true,
			wishlist: [],
			copy: false,
			addTab: false,
			editTab: false,
			points: "",
			orderedProduct: [],
			editPhone: "",
			pincode: "",
			editPincode: "",
			city: "",
			editCity: "",
			country: "",
			editCountry: "",
			state: "",
			editState: "",
			firstName: "",
			editFirstName: "",
			lastName: "",
			editLastName: "",
			appartment: "",
			editAppartment: "",
			address: "",
			editAddress: "",
			email: "",
			editEmail: "",
			redeem: "",
			selectedTab: "",
			user: "",
			cname: ""
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		firebase.auth().onAuthStateChanged(async(user) => {
			if (user) {
				firebase
					.firestore()
					.collection("users")
					.where("uid", "==", user.uid)
					.onSnapshot((snap) => {
						snap.docChanges().forEach((change) => {
							console.log(change.doc.data())
							this.setState({
								currentUser: change.doc.data(),
								points: change.doc.data().points,
								addresses: change.doc.data().addresses,
								loading: false,
								orders: change.doc.data().orders,
								bills: change.doc.data().bills || []
							});
						});
					});	
				const billsArr= [];
				const customerEmail = user.email; // replace with the email you want to filter by
				const customerPhone = user.phoneNumber; // replace with the email you want to filter by
		
				const query = firebase.firestore()
					.collection("billings")
					

				query.get().then((querySnapshot) => {
					querySnapshot.forEach((doc) => {
					
						if (doc.data().customer[0].email === customerEmail || doc.data().customer[0].phone === customerPhone)
						billsArr.push(doc.data());
					});
	
					this.setState({
						bills: billsArr
					})
				}).catch((error) => {
					console.error("Error fetching documents: ", error);
				});
				} else {
				window.location.href = "/";
			}
		});
	}

	handleProfile = () => {
		this.setState({
			editProifle: true,
			name: this.state.currentUser.name,
			email: this.state.currentUser.email,
			dob: this.state.currentUser.dob,
			gender: this.state.currentUser.gender,
			phone: this.state.currentUser.phone,
			alt: this.state.currentUser.alt
		});
	};

	handleProfileCancel = () => {
		this.setState({
			editProifle: false
		});
	};

	handleProfileSave = () => {
		// this.setState({
		//   editProifleLoading: true,
		// });
		firebase
			.firestore()
			.collection("users")
			.where("uid", "==", firebase.auth().currentUser.uid)
			.get()
			.then((snap) => {
				snap.forEach((doc) => {
					firebase
						.firestore()
						.collection("users")
						.doc(doc.id)
						.update({
							name: this.state.name,
							email: this.state.email,
							phone: this.state.phone,
							gender: this.state.gender,
							dob: this.state.dob
						})
						.then(() => {
							this.setState({
								// editProifleLoading: false,
								editProifle: false
							});
							toaster.notify("Profile Updated");
							// toaster.notfiy("Profile Updated");
						});
				});
			});
	};

	handleChange = (e) => {
		const { value, id } = e.target;
		this.setState({ [id]: value });
	};

	handleDelete(index) {
		var addresses = this.state.addresses;
		var newAddresses = addresses.filter((address) => address !== addresses[index]);
		firebase
			.firestore()
			.collection("users")
			.where("email", "==", firebase.auth().currentUser.email)
			.get()
			.then((snap) => {
				snap.forEach((doc) => {
					firebase
						.firestore()
						.collection("users")
						.doc(doc.id)
						.update({
							addresses: newAddresses
						})
						.then(() => {
							this.setState({
								addresses: newAddresses
							});
						});
				});
			});
	}

	handleAdd() {
		if (
			this.state.address.length > 0 &&
			this.state.cName.length > 0 &&
			this.state.phone.length > 0 &&
			this.state.city.length > 0 &&
			this.state.state.length > 0 &&
			this.state.country.length > 0 &&
			this.state.pincode.length > 0
		) {
			var addresses = {};
			addresses.address = this.state.address;
			addresses.cname = this.state.cName;
			addresses.country = this.state.country;
			addresses.state = this.state.state;
			addresses.city = this.state.city;
			addresses.pincode = this.state.pincode;
			addresses.cphone = this.state.phone;
			this.setState({
				addresses: [...this.state.addresses, addresses]
			});
			firebase
				.firestore()
				.collection("users")
				.where("email", "==", firebase.auth().currentUser.email)
				.get()
				.then((snap) => {
					snap.forEach((doc) => {
						firebase
							.firestore()
							.collection("users")
							.doc(doc.id)
							.update({
								addresses: this.state.addresses
							})
							.then(() => {
								this.setState({
									firstName: "",
									lastName: "",
									phone: "",
									email: "",
									country: "",
									address: "",
									state: "",
									city: "",
									pincode: "",
									appartment: "",
									addTab: false
								});
							});
					});
				});
		} else {
			toaster.notify("Please fill all the details");
		}
	}

	handleRedeem = () => {
		this.setState({
			circular: true
		});
		firebase
			.firestore()
			.collection("gift card")
			.where("name", "==", this.state.redeem)
			.get()
			.then((snap) => {
				if (snap.size > 0) {
					snap.forEach((doc) => {
						if (!doc.data().redeem) {
							if (doc.data().type === "marfit user") {
								firebase
									.firestore()
									.collection("users")
									.where("email", "==", this.state.currentUser.email)
									.get()
									.then((snap) => {
										snap.forEach((doc2) => {
											var points = parseInt(doc2.data().points) + parseInt(doc.data().value);
											var pointsHistory = doc2.data().pointsHistory;
											var newPointData = {
												date: new Date(),
												points: parseInt(doc.data().value),
												reason: "Gift Card " + this.state.redeem
											};
											pointsHistory.push(newPointData);
											firebase
												.firestore()
												.collection("users")
												.doc(doc2.id)
												.update({
													points: points,
													pointsHistory: pointsHistory
												})
												.then(() => {
													toaster.notify("Redeem of " + doc.data().value + " points is Successfull");
													this.setState({
														circular: false
													});
												});
										});
									});
							} else {
								firebase
									.firestore()
									.collection("approval")
									.add({
										email: this.state.currentUser.email,
										phone: this.state.currentUser.phone,
										giftCard: doc.data().name,
										orderId: doc.data().orderId,
										date: new Date(),
										status: "pending"
									})
									.then(() => {
										toaster.notify("Redeem points successfully requested");
									});
							}
						} else {
							toaster.notify("You already claimed this offer");
							this.setState({
								circular: false
							});
						}
					});
				} else {
					toaster.notify("Invalid gift card code");
					this.setState({
						circular: false
					});
				}
			});
	};

	editTab = (index) => {
		this.setState({
			editAddress: this.state.addresses[index].address,
			editPhone: this.state.addresses[index].cphone,
			editName: this.state.addresses[index].cname,
			editPincode: this.state.addresses[index].pincode,
			editState: this.state.addresses[index].state,
			editCity: this.state.addresses[index].city,
			editCountry: this.state.addresses[index].country,
			editTab: true,
			selectedTab: index
		});
	};

	handleEdit = () => {
		var address = {};
		address.address = this.state.editAddress;
		address.cphone = this.state.editPhone;
		address.cname = this.state.editName;
		address.pincode = this.state.editPincode;
		address.state = this.state.editState;
		address.city = this.state.editCity;
		address.country = this.state.editCountry;
		var addresses = this.state.addresses;
		addresses[this.state.selectedTab] = address;
		firebase
			.firestore()
			.collection("users")
			.where("email", "==", this.state.currentUser.email)
			.get()
			.then((snap) => {
				snap.forEach((doc) => {
					console.log(this.state.addresses);
					firebase
						.firestore()
						.collection("users")
						.doc(doc.id)
						.update({
							addresses: addresses
						})
						.then(() => {
							this.setState({
								editTab: false
							});
							toaster.notify("Address updates successfully");
						});
				});
			});
	};

	render() {
		console.log(this.state.orders);
		return (
			<>
				{this.state.loading ? (
					<div
						style={{
							width: "100%",
							height: "100vh",
							display: "flex",
							alignItems: "center",
							justifyContent: "center"
						}}>
						<Lottie options={{ animationData: loading }} width={150} height={150} />
					</div>
				) : (
					<motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
						<div className="dashboard-container">
							<div className="sidebar">
								{this.state.tab === "Profile" ? (
									<div className="menu-active">Profile</div>
								) : (
									<div className="menu" onClick={() => this.setState({ tab: "Profile" })}>
										Profile
									</div>
								)}
								{this.state.tab === "Orders" ? (
									<div className="menu-active">Orders</div>
								) : (
									<div className="menu" onClick={() => this.setState({ tab: "Orders" })}>
										Orders
									</div>
								)}
								{this.state.tab === "Wishlist" ? (
									<div className="menu-active">Wishlist</div>
								) : (
									<div className="menu" onClick={() => this.setState({ tab: "Wishlist" })}>
										Wishlist
									</div>
								)}
								{this.state.tab === "Address" ? (
									<div className="menu-active">Address</div>
								) : (
									<div className="menu" onClick={() => this.setState({ tab: "Address" })}>
										Address
									</div>
								)}
								{this.state.tab === "Refer" ? (
									<div className="menu-active">Refer & Earn</div>
								) : (
									<div className="menu" onClick={() => this.setState({ tab: "Refer" })}>
										Refer & Earn
									</div>
								)}
								{this.state.tab === "Redeem" ? (
									<div className="menu-active">Redeem</div>
								) : (
									<div className="menu" onClick={() => this.setState({ tab: "Redeem" })}>
										Redeem
									</div>
								)}
							</div>
							<div className="content">
								{this.state.tab === "Profile" ? (
									<>
										<h1>Profile Details</h1>
										<div className="divider"></div>
										<div className="input-list">
											<div className="input-group">
												<p className="title">User Name</p>
												{this.state.editProifle ? (
													<input id="name" type="text" value={this.state.name} placeholder="Enter your user name" onChange={this.handleChange} />
												) : (
													<p className="value">{this.state.currentUser ? this.state.currentUser.name : "N/A"}</p>
												)}
											</div>
											{this.state.currentUser 
											// && this.state.currentUser.loginMethod === "Email" 
											? (
												<div className="input-group">
													<p className="title">Email</p>
													<p className="value">{this.state.currentUser ? this.state.currentUser.email : "N/A"}</p>
												</div>
											) : null}
											{this.state.currentUser
											//  && this.state.currentUser.loginMethod === "Phone" 
											 ? (
												this.state.currentUser.loginMethod === "Phone" ?
												<div className="input-group">
													<p className="title">Mobile No.</p>
													<p className="value">{this.state.currentUser ? (this.state.currentUser.phone ? this.state.currentUser.phone : "N/A") : "N/A"}</p>
															</div> : <div className="input-group">
																<p className="title">Mobile No.</p>
																{this.state.editProifle ? (
																	<input id="phone" type="number" defaultValue={this.state.currentUser.phone} placeholder="Enter your phone number" onChange={this.handleChange} />
																) : (
																	<p className="value">{this.state.currentUser ? `+91${this.state.currentUser.phone}` : "N/A"}</p>
																)}
																
															</div>
											) : null}
											<div className="input-group">
												<p className="title">Gender</p>
												{this.state.editProifle ? (
													<div className="gender-group">
														<div className="gender">
															{this.state.gender === "Male" ? (
																<i className="fas fa-dot-circle activated"></i>
															) : (
																<i class="far fa-circle" onClick={() => this.setState({ gender: "Male" })}></i>
															)}
															<p>Male</p>
														</div>
														<div className="gender">
															{this.state.gender === "Female" ? (
																<i className="fas fa-dot-circle activated"></i>
															) : (
																<i className="far fa-circle " onClick={() => this.setState({ gender: "Female" })}></i>
															)}
															<p>Female</p>
														</div>
													</div>
												) : (
													<p className="value">{this.state.currentUser ? (this.state.currentUser.gender ? this.state.currentUser.gender : "N/A") : "N/A"}</p>
												)}
											</div>
											<div className="input-group">
												<p className="title">Date of Birth</p>
												{this.state.editProifle ? (
														<input id="dob" type="date" disabled={this.state.currentUser.dob} maxLength={8} value={this.state.dob} onChange={this.handleChange} />
												) : (
													<p className="value">{this.state.currentUser ? (this.state.currentUser.dob ? this.state.currentUser.dob : "N/A") : "N/A"}</p>
												)}
											</div>
										</div>
										{this.state.editProifle ? (
											<>
												{this.state.editProifleLoading ? (
													<div className="loader-container">
														<div className="loader">
															<Lottie options={{ animationData: loading }} />
														</div>
													</div>
												) : (
													<div className="button-group">
														<div className="profile-cancel-button" onClick={this.handleProfileCancel}>
															Cancel
														</div>
														<div className="profile-save-button" onClick={this.handleProfileSave}>
															Save
														</div>
													</div>
												)}
											</>
										) : (
											<div className="profile-edit-button" onClick={this.handleProfile}>
												Edit Profile
											</div>
										)}
									</>
								) : null}
								{this.state.tab === "Orders" ? (
									<>
										<h1>Your Orders</h1>
										<div className="divider"></div>
										{(this.state.orders.length === 0 && this.state.bills.length === 0) ? (
											<div
												className="ordersdiv"
												style={{
													width: "100%",
													height: "90%",
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													justifyContent: "center"
												}}>
												<Lottie options={{ animationData: empty }} width={200} height={200} />
												<p>
													<span>EMPTY ORDER LIST</span>
													<br />
													You have no items in your orderlist
												</p>
												<a href="/" className="empty">
													Take me back to shopping
												</a>
											</div>
										) : (
											<Fragment>
												
										{	(this.state.orders.length > 0 && (<div className="order-container">
												{this.state.orders.map((item, index) => {
													console.log("hello",this.state.orders);
													return <OrdersComp item={item} key={index} />;
												})}
											</div>))}

											{this.state.bills.length > 0 && (<div className="order-container">
												{this.state.bills.map((item, index) => {
													return <div style={{ marginBottom: "35px" }}>
														<div style={{ display :"flex", justifyContent: "space-between", width: "98%" }}>
													<h3>Bill No. {item.billNo}</h3>
													<a href={`/bill?id=${item.billCode}`}>View Invoice</a>
														</div>
													{item.purchasedProducts?.map((product, index) => {
														return <BillCard item={product} order={item} />;
													})}
													</div>
												})}
											</div>)}
													</Fragment>

										)}
									</>
								) : null}
								{this.state.tab === "Wishlist" ? (
									<>
										<h1>Your Wishlist Items</h1>
										<div className="divider"></div>
										{this.state.currentUser.wishlist.length === 0 ? (
											<div
												style={{
													width: "100%",
													height: "80%",
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													justifyContent: "center"
												}}>
												<Lottie options={{ animationData: emptywish }} width={260} height={300} />
												<a href="/" className="empty">
													Take me back to shopping
												</a>
											</div>
										) : (
											<div className="wishlist-container">
												{this.state.currentUser.wishlist.map((item) => {
													var product = {};
													product.id = item;
													return <Card key={item} item={product} />;
												})}
											</div>
										)}
									</>
								) : null}
								{this.state.tab === "Address" ? (
									<>
										<h1>Your Address List</h1>
										<div className="divider"></div>
										<div className="address-cont">
											{this.state.addTab ? (
												<>
													<i
														className="fas fa-arrow-left"
														onClick={() => {
															this.setState({ addTab: false });
														}}></i>
													<div className={this.state.addTab ? "addtab active" : "addtab"}>
														<div className="inputs">
															<input
																type="text"
																placeholder="Name"
																name="cName"
																id="cName"
																required
																value={this.state.cName}
																maxLength={20}
																className="address-input"
																onChange={this.handleChange}
															/>
															<input
																type="text"
																maxLength={50}
																placeholder="Address"
																name="address"
																id="address"
																required
																className="address-input"
																value={this.state.address}
																onChange={this.handleChange}
															/>
															<div className="region">
																<input
																	type="text"
																	placeholder="Country / Nation"
																	name="country"
																	required
																	id="country"
																	value={this.state.country}
																	onChange={this.handleChange}
																	className="address-input"
																/>
																<input
																	type="text"
																	className="address-input"
																	placeholder="State"
																	name="state"
																	required
																	id="state"
																	value={this.state.state}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="region">
																<input
																	type="text"
																	className="address-input"
																	placeholder="City"
																	name="city"
																	required
																	id="city"
																	value={this.state.city}
																	onChange={this.handleChange}
																/>
																<input
																	type="text"
																	className="address-input"
																	placeholder="Pincode"
																	name="pincode"
																	required
																	id="pincode"
																	value={this.state.pincode}
																	onChange={this.handleChange}
																/>
															</div>
															<PhoneInput
																country={"in"}
																onlyCountries={["in"]}
																disableDropdown={true}
																disableCountryCode={true}
																value={this.state.phone}
																onChange={(phone) => this.setState({ phone })}
																placeholder="Enter your phone number"
																className="phone-input"
															/>
															<button
																type="button"
																onClick={() => {
																	this.handleAdd();
																}}>
																<p>Add</p>
															</button>
														</div>
													</div>
												</>
											) : this.state.editTab ? (
												<>
													<i
														className="fas fa-arrow-left"
														onClick={() => {
															this.setState({ editTab: false });
														}}></i>
													<div className={this.state.editTab ? "editTab active" : "editTab"}>
														<div className="inputs">
															<input
																type="text"
																placeholder="Name"
																name="editName"
																id="editName"
																required
																maxLength={20}
																className="address-input"
																value={this.state.editName}
																onChange={this.handleChange}
															/>
															<input
																type="text"
																placeholder="Address"
																name="editAddress"
																id="editAddress"
																required
																maxLength={50}
																className="address-input"
																value={this.state.editAddress}
																onChange={this.handleChange}
															/>
															<div className="region">
																<input
																	type="text"
																	placeholder="Country / Nation"
																	name="editCountry"
																	required
																	id="editCountry"
																	className="address-input"
																	value={this.state.editCountry}
																	onChange={this.handleChange}
																/>
																<input
																	type="text"
																	placeholder="State"
																	name="editState"
																	required
																	id="editState"
																	className="address-input"
																	value={this.state.editState}
																	onChange={this.handleChange}
																/>
															</div>
															<div className="region">
																<input
																	type="text"
																	className="address-input"
																	placeholder="City"
																	name="editCity"
																	required
																	id="editCity"
																	value={this.state.editCity}
																	onChange={this.handleChange}
																/>
																<input
																	type="text"
																	placeholder="Pincode"
																	name="editPincode"
																	required
																	id="editPincode"
																	className="address-input"
																	value={this.state.editPincode}
																	onChange={this.handleChange}
																/>
															</div>
															<PhoneInput
																country={"in"}
																onlyCountries={["in"]}
																disableDropdown={true}
																disableCountryCode={true}
																value={this.state.editPhone}
																onChange={(editPhone) => this.setState({ editPhone })}
																placeholder="Enter your phone number"
																className="phone-input"
															/>
															<button
																type="button"
																onClick={() => {
																	this.handleEdit();
																}}>
																<p>Save</p>
															</button>
														</div>
													</div>
												</>
											) : (
												<>
													{this.state.addresses &&
														this.state.addresses.map((address, index) => (
															<div className="address" key={index}>
																<div className="paras">
																	<p>{address.cname}</p>
																	<p>{address.address}</p>
																</div>
																<div className="actions">
																	<div
																		className="edit"
																		onClick={() => {
																			this.editTab(index);
																		}}>
																		<i className="fas fa-pen"></i>
																	</div>
																	<div
																		className="minus"
																		onClick={() => {
																			this.handleDelete(index);
																		}}>
																		<i className="fas fa-trash"></i>
																	</div>
																</div>
															</div>
														))}

													<div
														className="newAddress"
														onClick={() => {
															this.setState({ addTab: true });
														}}>
														<i className="fas fa-plus-circle"></i>
														<p>ADD NEW ADDRESS</p>
													</div>
												</>
											)}
										</div>
									</>
								) : null}
								{this.state.tab === "Refer" ? (
									<>
										<div className="refer-header">
											<h1>Refer & Earn</h1>
											<div className="right">
												<h3>Marfit Points: {this.state.currentUser.points}</h3>
											</div>
										</div>
										<div className="referMain">
											<div className="referanimation">
												<Lottie options={{ animationData: refer }} width={280} height={280} style={{ position: "absolute", top: 0 }} />
											</div>
											<div className="referCode">
												<div className="referInput">
													<input type="text" value={this.state.currentUser.referalID} disabled id="referalText" />
													<CopyToClipboard text={this.state.currentUser.referalID}>
														<button
															type="button"
															className={this.state.copy ? "copiedButton" : "copyButton"}
															onClick={() => {
																this.setState({ copy: true });
																setTimeout(() => {
																	this.setState({
																		copy: false
																	});
																}, 1000);
															}}>
															{!this.state.copy ? (
																<>
																	<p>Copy</p>
																	<i className="far fa-clipboard"></i>
																</>
															) : (
																<>
																	<p>Copied</p>
																	<i className="fas fa-check"></i>
																</>
															)}
														</button>
													</CopyToClipboard>
												</div>
												<div className="pointHistory">
													{this.state.currentUser.pointsHistory.map((point) => {
														return (
															<div className="point-container">
																<div className="point-header">
																	<p>{point.reason ? point.reason : "Order"}</p>
																	<p>{moment(point.date.toDate()).format("DD/MM/YYYY")}</p>
																</div>
																<p className={point.points > 0 ? "postive-points" : "negative-points"}>{point.points}</p>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</>
								) : null}
								{this.state.tab === "Redeem" ? (
									<>
										<div className="redeemHead">
											<div className="left">
												<h1>Redeem</h1>
											</div>
										</div>
										<div className="redeem-cont">
											<div className="redeemCard">
												<Lottie options={{ animationData: redeem }} width={270} height={270} style={{ position: "absolute", top: "-100px" }} />
											</div>
											<div className="redeemCode">
												<div className="redeemInput">
													<input type="text" id="redeem" onChange={this.handleChange} placeholder="XXXX-XXXX-XXXX" />
													{this.state.circular ? (
														<button type="button" disabled className="redeem active">
															<Lottie options={{ animationData: circular }} width={30} height={30} />
														</button>
													) : (
														<button
															type="button"
															className={this.state.redeem.length > 0 ? "redeem active" : "redeem"}
															onClick={() => {
																this.handleRedeem();
															}}>
															Redeem
														</button>
													)}
												</div>
											</div>
										</div>
									</>
								) : null}
							</div>
						</div>
					</motion.div>
				)}
			</>
		);
	}
}

export default Dashboard;
