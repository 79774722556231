import React, { Component } from "react";
import "./OrdersComp.css";
import firebase from "firebase";
import Lottie from "lottie-react-web";
import loading from "../../../assets/loading.json";
import OrderCard from "../OrderCard/OrderCard";
import axios from "axios";
import link from "../../../fetchPath";

class OrdersComp extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			orderimg: null,
			ordertitle: null,
			orderdate: null,
			ordertotal: null,
			orderstatus: null,
			totalproducts: null,
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection("orders")
			.doc(this.props.item)
			.onSnapshot(async (order) => {
				var orderInfo = order.data();
				console.log(orderInfo);
				orderInfo.id = order.id;
				if (orderInfo.tracking) {
					var data = {
						awb: orderInfo.tracking,
					};
					var deliveryDate = null;
					var res = await axios.post(link + "/api/trackOrder", data);
					var status = orderInfo.status;
					if (!status.includes(res.data.shipment_status)) {
						status.push(res.data.shipment_status);
					}
					if (res.data.shipment_status === 7) {
						console.log(res.data.edd);
						deliveryDate = new Date(res.data.edd);
					}
					orderInfo.status = status;
					this.setState(
						{
							trackStatus: res.data.track_status,
							status: status,
							shipmentActivities: res.data.shipment_track_activities,
							trackUrl: res.data.track_url,
							orderInfo,
							loading: false,
						},
						() => {
							firebase.firestore().collection("orders").doc(this.props.item).update({
								status: orderInfo.status,
								deliveryDate,
							});
						}
					);
				} else {
					this.setState({
						orderInfo,
						loading: false,
					});
				}
			});
	}

	render() {
		console.log(this.state.totalproducts);
		return (
			<>
				{this.state.orderInfo &&
					this.state.orderInfo.products.map((product) => {
						return <OrderCard item={product} order={this.state.orderInfo} />;
					})}
			</>
		);
	}
}

export default OrdersComp;
