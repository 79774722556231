import React, { Component } from "react";
import "./TrackOrder.css";
import "react-phone-input-2/lib/style.css";
import Lottie from "lottie-react-web";
import loading from "../../../assets/loading.json";
import toaster from "toasted-notes";
import firebase from "firebase";

export class TrackOrder extends Component {
	constructor() {
		super();
		this.state = {
			orderId: "",
			sending: false
		};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	handleChange = (e) => {
		const { value, id } = e.target;
		this.setState({ [id]: value });
	};

	handleSubmit = async () => {
		var { orderId } = this.state;
		if (this.state.orderId === "") {
			toaster.notify("Please enter a proper order id!");
		} else {
			this.setState({
				sending: true
			});
			console.log(orderId);
			var snap = await firebase.firestore().collection("orders").doc("5ViniV3URlFyBWjDOCAZ").get();
			if (snap.exists) {
				this.setState({
					sending: false
				});
				window.location.href = "Orders/" + orderId;
			} else {
				toaster.notify("No such order exists!");
				this.setState({
					sending: false
				});
			}
		}
	};

	render() {
		return (
			<div className="trackorder-container">
				<div className="trackorder-form-container">
					<h1>Track Your Order</h1>
					<div className="form-inputs">
						<p>Order Id</p>
						<input className="form-input" value={this.state.orderId} onChange={this.handleChange} id="orderId" />
					</div>
					{this.state.sending ? <Lottie options={{ animationData: loading }} width={50} height={50} /> : <button onClick={this.handleSubmit}>Send</button>}
				</div>
			</div>
		);
	}
}

export default TrackOrder;
