import React from "react";
import "./OrderCard.css";
import moment from "moment";

const OrderCard = (props) => {
	return (
		<div
			className='order-card'
			onClick={() => {
				window.location.href = "/Orders/" + props.order?.id;
			}}>
			<div className='product-section1'>
				<div className='product-image'>
					<img src={props.item?.thumbnail} alt='product-image' />
				</div>
				<div className='product-info'>
					<p className='product-title'>
						{props.item?.title}
						<br />
						{props.item?.emboss ? " (with emboss: " + props.item?.embossText + ")" : null}
					</p>
					<p className='product-color'>Color : {props.item?.color}</p>
					<p className='product-quantity'>Quantity : {props.item?.quantity}</p>
					{props.item?.size !== "null" ? (
						<p className='product-size'>
							Size :<span> {props.item?.size}</span>
						</p>
					) : null}
				</div>
			</div>
			<div className='product-section2'>
				<p>₹{props.order?.total}</p>
			</div>
			<div className='product-section3'>
				{props.order.varient === "default" ? (
					<>
						{props.order.status.includes(7) ? (
							<>
								<div className='status-info'>
									<div className='status-indicator green'></div>
									<p>Delivered on {moment(props.order.deliveryDate.toDate()).format("DD-MM-YYYY,hh:mm a")}</p>
								</div>
								<p className='product-status-message'>Your Item has been delivered</p>
							</>
						) : (
							<>
								{props.order.status.includes(17) ? (
									<>
										<div className='status-info'>
											<div className='status-indicator green'></div>
											<p>Ordered on {moment(typeof (props.order.date) === "string" ? props.order.date : props.order.date.toDate()).format("DD-MM-YYYY,hh:mm a")}</p>
										</div>
										<p className='product-status-message'>Your Item is out for delivery</p>
									</>
								) : (
									<>
										{props.order.status.includes(6) ? (
											<>
												<div className='status-info'>
													<div className='status-indicator green'></div>
													<p>Ordered on {moment(typeof (props.order.date) === "string" ? props.order.date : props.order.date.toDate()).format("DD-MM-YYYY,hh:mm a")}</p>
												</div>
												<p className='product-status-message'>Your Item is packed and ready to ship</p>
											</>
										) : (
											<>
												{props.order.status.includes(0) ? (
													<>
														<div className='status-info'>
															<div className='status-indicator green'></div>
															<p>Ordered on {moment(typeof (props.order.date) === "string" ? props.order.date : props.order.date.toDate()).format("DD-MM-YYYY,hh:mm a")}</p>
														</div>
														<p className='product-status-message'>Your Item is under process</p>
													</>
												) : null}
											</>
										)}
									</>
								)}
							</>
						)}
					</>
				) : null}
				{props.order.varient === "Return" ? (
					<>
						<div className='status-info'>
							<div className='status-indicator yellow'></div>
							<p>Return</p>
						</div>
					</>
				) : null}
				{props.order.varient === "Replace" ? (
					<>
						<div className='status-info'>
							<div className='status-indicator yellow'></div>
							<p>Replace</p>
						</div>
					</>
				) : null}
				{props.order.varient === "Cancel" ? (
					<>
						<div className='status-info'>
							<div className='status-indicator red'></div>
							<p>Cancelled</p>
						</div>
						<p className='product-status-message'>As per your request, your item has been cancelled</p>
					</>
				) : null}
				{props.item.rate && props.item.status === "default" ? (
					<div className='product-rating'>
						<i className={props.item.ratings.stars > 0 ? "fas fa-star active" : "far fa-star "}></i>
						<i className={props.item.ratings.stars > 1 ? "fas fa-star active" : "far fa-star "}></i>
						<i className={props.item.ratings.stars > 2 ? "fas fa-star active" : "far fa-star "}></i>
						<i className={props.item.ratings.stars > 3 ? "fas fa-star active" : "far fa-star "}></i>
						<i className={props.item.ratings.stars > 4 ? "fas fa-star active" : "far fa-star "}></i>
					</div>
				) : null}
			</div>
		</div>
	);
};

export default OrderCard;
