import React, { Component } from "react";
import "./Invoice.css";
import logo from "../../../assets/Marfit.png";
import moment from "moment";

export default class Invoice extends Component {
	render() {
		return (
			<div className='invoice-box'>
				<div className='invoice-header'>
					<h1>Tax Invoice</h1>
					<div className='header-section'>
						<p></p>
						<p></p>
					</div>
					<div className='header-divider'></div>
					<div className='header-section'>
						<p>
							Invoice No: <span>{this.props.order?.invoiceNo || this.props.order?.id}</span>
						</p>
						<p>Invoice Date: {moment(this.props.order.date.toDate()).format("DD-MM-YYYY,hh:mm a")}</p>
					</div>
					<div className='header-divider'></div>
					<div className='header-section'>
						<p>GSTIN : 19BCYPK3468D1Z1</p>
						<p>PAN : BCYPK3468D</p>
					</div>
				</div>
				<div className='invoice-location'>
					<div className='location-section'>
						<h1>Sold By</h1>
						<p>Marfit</p>
						<p>Address 9R Abhinash Chowdhury Lane</p>
						<p>KOLKATA - 700046,WB,IN</p>
					</div>
					<div className='location-section'>
						<h1>Shipping Address</h1>
						<p>{this.props.order.name}</p>
						<p>{this.props.order.addressSelected.address}</p>
						<p>
							{this.props.order.addressSelected.city} - {this.props.order.addressSelected.pin},{this.props.order.addressSelected.state},{this.props.order.addressSelected.country}
						</p>
					</div>
					<div className='location-section'>
						<h1>Billing Address</h1>
						<p>{this.props.order.name}</p>
						<p>{this.props.order.addressSelected.address}</p>
						<p>
							{this.props.order.addressSelected.city} - {this.props.order.addressSelected.pin},{this.props.order.addressSelected.state},{this.props.order.addressSelected.country}
						</p>
					</div>
				</div>
				<div className='product-list'>
					<div className='product-list-header'>
						<h1>Product</h1>
						<h1>Description</h1>
						<h1>Qty</h1>
						<h1>Gross Amount</h1>
						<h1>Discount</h1>
						<h1>Taxable Value</h1>
						<h1>GST</h1>
						<h1>Total</h1>
					</div>
					<div className='product-info'>
						<p>{this.props.order.products[0].title}</p>
						<p>
							HSN: {this.props.order.products[0].hsn} | GST: {this.props.order.products[0].gst}%
						</p>
						<p>{this.props.order.products[0].quantity}</p>
						<p>{this.props.order.products[0].sp.toFixed(2)}</p>
						<p>-{this.props.order.products[0].dPrice.toFixed(2)}</p>
						<p>{(this.props.order.products[0].sp.toFixed(2) - this.props.order.products[0].sp.toFixed(2) * (this.props.order.products[0].gst / 100).toFixed(2)).toFixed(2)}</p>
						<p>{Math.round(this.props.order.products[0].sp.toFixed(2) * (this.props.order.products[0].gst / 100).toFixed(2)).toFixed(2)}</p>
						<p>{Math.round(this.props.order.products[0].sp.toFixed(2) - this.props.order.products[0].dPrice.toFixed(2)).toFixed(2)}</p>
					</div>
					<div className='shipping-section'>
						<p></p>
						<p>Shipping Charge</p>
						<p>{this.props.order.products[0].quantity}</p>
						<p>{this.props.order.type === "Store-Pickup" ? 0 : this.props.order.products[0].shippingCharge.toFixed(2)}</p>
						<p>0.00</p>
						<p>0.00</p>
						<p>0.00</p>
						<p>{this.props.order.type === "Store-Pickup" ? 0 : this.props.order.products[0].shippingCharge.toFixed(2)}</p>
					</div>
					<div className='product-quanity'>
						<h1>TOTAL QTY : {this.props.order.products[0].quantity}</h1>
						<div className='product-price'>
							<h1>TOTAL PRICE : {this.props.order.total.toFixed(2)}</h1>
							<p>All values are in INR</p>
						</div>
					</div>
				</div>
				<div className='invoice-footer'>
					<img src={logo} alt='logo' />
				</div>
			</div>
		);
	}
}
