import React from "react";
import Loader from "../../../../Components/Loader/Loader";
import "./Banner.css";
import firebase from "firebase";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
SwiperCore.use([Navigation, Pagination, A11y]);

export default class Banner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			banner: [],
			loading: true
		};
	}

	componentDidMount() {
		firebase
			.firestore()
			.collection("settings")
			.onSnapshot((snap) => {
				snap.docChanges().forEach((changes) => {
					this.setState({
						banner: changes.doc.data().topBanner,
						loading: false
					});
				});
			});
	}

	render() {
		return (
			<div className="panel-container">
				{this.state.loading ? (
					<Loader />
				) : (
					<div className="panel">
						<Swiper spaceBetween={50} slidesPerView={1} navigation pagination={{ clickable: true }} onSwiper={(swiper) => console.log(swiper)} onSlideChange={() => console.log("slide change")}>
							{this.state.banner.map((b) => {
								return (
									<SwiperSlide>
										<a href={b.link} className="cont">
											<img src={b.url} alt="banner-image" />
										</a>
									</SwiperSlide>
								);
							})}
						</Swiper>
					</div>
				)}
			</div>
		);
	}
}
