import React from "react";
import "./order.css";
import firebase from "firebase";
import OrderCard from "../../Components/OrderCard/OrderCard";
import loading from "../../../assets/loading.json";
import circleLoading from "../../../assets/circular loading.json";
import Lottie from "lottie-react-web";
import toaster from "toasted-notes";
import Loader from "../../Components/Loader/Loader";
import axios from "axios";
import moment from "moment";
import CheckOutCard2 from "../../Components/CheckOutCard2/CheckOutCard2";
import link from "../../../fetchPath";

export default class Order extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			userEmail: "",
			order: "",
			loading: true,
			status: [],
			return: false,
			returnReason: "",
			returnNext: false,
			cancel: false,
			cancelReason: "",
			cancelNext: false,
			replacement: false,
			replacementReason: "",
			replacementNext: false,
			starCount: 0,
			review: "",
			reviewProductCount: 0,
			loggedIn: false,
			modal: true,
			btnLoading: false,
			rateProducts: [],
			userName: "",
			trackStatus: 0,
			shipmentStaus: 0,
			shipmentActivities: [],
			trackUrl: "",
			selectedProduct: null,
			showReturn: false,
			showReplace: false
		};
		this.windowOffSet = 0;
	}

	componentDidMount() {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				this.setState({
					loggedIn: true,
					userEmail: user.email
				});
			}
		});
		firebase
			.firestore()
			.collection("orders")
			.doc(this.props.match.params.id)
			.get()
			.then((doc) => {
				console.log(doc.data());
				var order = doc.data();
				order.id = doc.id;
				this.setState(
					{
						order,
						status: doc.data().status
					},
					async () => {
						console.log(this.state.order.tracking);
						var data = {
							awb: this.state.order.tracking
						};
						if (this.state.order.tracking) {
							var deliveryDate = null;
							var res = await axios.post(link + "/api/trackOrder", data);
							var status = this.state.status;
							if (!status.includes(res.data.shipment_status)) {
								status.push(res.data.shipment_status);
							}
							if (res.data.shipment_status === 7) {
						
								deliveryDate = new Date(res.data.edd);
								const date1 = new Date();
								const date2 = deliveryDate;
								const diffTime = date2 - date1;
								const diffDays = diffTime / (1000 * 60 * 60 * 24);
							
								if (diffDays + order.products[0].replacementlimit > 0) {
									this.setState({
										showReplace: true
									});
								}
								if (diffDays + order.products[0].returnlimit > 0) {
									this.setState({
										showReturn: true
									});
								}
							}
							this.setState(
								{
									trackStatus: res.data.track_status,
									status: status,
									shipmentActivities: res.data.shipment_track_activities,
									trackUrl: res.data.track_url
								},
								() => {
									firebase.firestore().collection("orders").doc(this.props.match.params.id).update({
										status: this.state.status,
										deliveryDate
									});
								}
							);
						}
						var products = [];
						if (this.state.order.status.includes(7)) {
							this.state.order.products.map((product) => {
								if (product.rate === false) {
									products.push(product);
								}
							});
						}

						this.setState(
							{
								rateProducts: products,
								loading: false
							},
							() => {
								if (this.state.rateProducts.length < 1) {
									this.setState({
										modal: false
									});
								} else {
									this.windowOffSet = window.scrollY;
									document.body.setAttribute("style", `position: fixed; top:-${this.windowOffSet}px; right: 0; left: 0;`);
								}
							}
						);
					}
				);
			});
	}

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	handleRate = () => {
		this.setState({
			btnLoading: true
		});
		if (this.state.starCount > 0) {
			firebase
				.firestore()
				.collection("products")
				.doc(this.state.rateProducts[this.state.reviewProductCount].id)
				.get()
				.then((snap) => {
					var ratings = snap.data().ratings;
					var rate = {};
					rate.stars = this.state.starCount;
					rate.review = this.state.review;
					rate.email = this.state.order.email;
					rate.date = new Date();
					rate.name = this.state.order.name;
					ratings.push(rate);
					firebase
						.firestore()
						.collection("products")
						.doc(snap.id)
						.update({
							ratings: ratings
						})
						.then(() => {
							firebase
								.firestore()
								.collection("orders")
								.doc(this.props.match.params.id)
								.get()
								.then((doc) => {
									var products = doc.data().products;
									products.forEach((product) => {
										if (product.id === this.state.rateProducts[this.state.reviewProductCount].id) {
											product.rate = true;
											product.ratings = rate;
										}
									});
									firebase
										.firestore()
										.collection("orders")
										.doc(this.props.match.params.id)
										.update({
											products: products
										})
										.then(() => {
											if (this.state.reviewProductCount < this.state.rateProducts.length - 1) {
												this.setState({
													btnLoading: false,
													starCount: 0,
													review: "",
													reviewProductCount: this.state.reviewProductCount + 1
												});
											} else {
												this.setState(
													{
														modal: false,
														btnLoading: false
													},
													() => {
														document.body.setAttribute("style", "");
														window.scrollTo(0, this.windowOffSet);
													}
												);
											}
										});
								});
						});
				});
		} else {
			toaster.notify("Please add stars to rate the product");
			this.setState({
				btnLoading: false
			});
		}
	};

	handleReturn = () => {
		firebase
			.firestore()
			.collection("orders")
			.doc(this.props.match.params.id)
			.update({
				varient: "Return",
				status: [0],
				tracking: "",
				reason: this.state.returnReason,
				verfied: false,
				returnDate: new Date()
			})
			.then(() => {
				window.location.href = "/Dashboard/Orders";
			});
	};

	handleReplacement = () => {
		firebase
			.firestore()
			.collection("orders")
			.doc(this.props.match.params.id)
			.update({
				varient: "Replace",
				status: [0],
				tracking: "",
				reason: this.state.replacementReason,
				verfied: false,
				replacementDate: new Date()
			})
			.then(() => {
				window.location.href = "/Dashboard/Orders";
			});
	};

	handleCancel = () => {
		firebase
			.firestore()
			.collection("orders")
			.doc(this.props.match.params.id)
			.update({
				varient: "Cancel",
				reason: this.state.cancelReason,
				cancelDate: new Date()
			})
			.then(() => {
				window.location.href = "/Dashboard/Orders";
			});
	};

	render() {
		var total = 0;
		this.state.order &&
			this.state.order.products.map((p) => {
				total += p.sp * p.quantity;
			});

		return (
			<div className="container">
				{this.state.loading ? (
					<Loader />
				) : (
					<div className="container-main">
						<div className="content">
							<div className="wrap">
								<div className="main">
									<main>
										<div className="head">{this.state.order.varient === "default" ? <p>Thank You for ordering from Marfit</p> : null}</div>
										<div className="section_content">
											{this.state.order.varient === "default" ? (
												<div className="status">
													<div className="icon">
														<>
															<div
																className={
																	this.state.status.includes(0) || this.state.status.includes(6) || this.state.status.includes(17) || this.state.status.includes(7)
																		? "done"
																		: "ordered"
																}>
																<i className="fas fa-check"></i>
																<span className="title">Ordered</span>
															</div>

															<div className={this.state.status.includes(6) || this.state.status.includes(17) || this.state.status.includes(7) ? "done" : "packed"}>
																<i className="fas fa-box"></i>
																<span className="title">Packed</span>
															</div>

															<div className={this.state.status.includes(17) || this.state.status.includes(7) ? "done" : "out-for-delivery"}>
																<i className="fas fa-truck"></i>
																<span className="title">Out for delivery</span>
															</div>

															<div className={this.state.status.includes(7) ? "done" : "delivered"}>
																<i className="fas fa-home"></i>
																<span className="title">Delivered</span>
															</div>
														</>
													</div>
												</div>
											) : null}

											<div className="order_update">
												<h2>Order updates</h2>
												<div className="order-status">
													{this.state.shipmentActivities?.length > 0 ? (
														this.state.shipmentActivities &&
														this.state.shipmentActivities?.map((activity) => {
															return (
																<div className="activity">
																	<h5>Activity: {activity.activity}</h5>
																	<p>Location: {activity.location}</p>
																	<p>Date: {moment(activity.date).format("MMMM Do YYYY, h:mm a")}</p>
																</div>
															);
														})
													) : (
														<p className="noUpdates">
															{this.state.order.varient !== "default"
																? this.state.order.verfied
																	? "Your order is verified and is in process"
																	: "Your order is currently being verified"
																: "Currently there are no updates available ..."}
														</p>
													)}
												</div>
											</div>
											<div className="customer_information">
												<h2>Customer information</h2>
												<div className="content_information">
													<div className="text_area_a">
														<h3>Shipping address</h3>
														<p>{this.state.order.address}</p>
													</div>
													<div className="text_area_b">
														<h3>Payment method</h3>
														<p>{this.state.order.paymentMethod}</p>
													</div>
													<div className="text_area_c">
														<h3>Pickup method</h3>
														<p>{this.state.order.type}</p>
													</div>
												</div>
											</div>
										</div>
										<div className="step_footer">
											<div className="replacement">
												<div className="need">
													<p>
															Need help? <a href="/Contact"><span>Contact</span></a>
													</p>
												</div>
											</div>
											{this.state.order.tracking === "" && this.state.order.varient === "default" && firebase.auth().currentUser ? (
												<div className="replacement-section">
													<button
														type="button"
														className="continue"
														onClick={() =>
															this.setState({ cancel: true, selectedProduct: this.state.order.products[0] }, () => {
																this.windowOffSet = window.scrollY;
																document.body.setAttribute("style", `position: fixed; top: -${this.windowOffSet}px; right: 0; left:0;`);
															})
														}>
														Cancel Item
													</button>
												</div>
											) : null}
											{this.state.order.status.includes(7) && this.state.order.varient === "default" && firebase.auth().currentUser ? (
												<div className="replacement-section">
													{this.state.showReturn ? (
														<span
															onClick={() =>
																this.setState({ return: true }, () => {
																	this.windowOffSet = window.scrollY;
																	document.body.setAttribute("style", `position: fixed; top: -${this.windowOffSet}px; right: 0; left:0;`);
																})
															}>
															Return
														</span>
													) : null}
													{this.state.showReplace ? (
														<button
															type="button"
															className="continue"
															onClick={() =>
																this.setState({ replacement: true }, () => {
																	this.windowOffSet = window.scrollY;
																	document.body.setAttribute("style", `position: fixed; top: -${this.windowOffSet}px; right: 0; left:0;`);
																})
															}>
															Replace
														</button>
													) : null}
												</div>
											) : null}
										</div>
									</main>
								</div>
							</div>
						</div>

						<div className="sidebar">
							<div className="items-container">{this.state.order && this.state.order.products.map((item, index) => <CheckOutCard2 product={item} key={index} />)}</div>
							<div className="order-details">
								<div className="shipping-sub">
									<p className="sub-title">Total</p>
									<p>+ &#8377; {total}</p>
								</div>
								<div className="shipping-sub">
									<p className="sub-title">Shipping</p>
									<p>+ &#8377; {this.state.order.shipping}</p>
								</div>
								{this.state.order.products[0].dPrice ? (
									<div className="shipping-sub">
										<p className="sub-title">Discount</p>
										<p>- &#8377;{this.state.order.products[0].dPrice}</p>
									</div>
								) : null}
							</div>
							<div className="total">
								<p>SUB-TOTAL</p>
								<p>&#8377; {this.state.order.total}</p>
							</div>
							<button
								className="invoice-button"
								onClick={() => {
									window.open("/OrderInvoice/" + this.state.order.id, "_blank");
								}}>
								View Invoice
							</button>
						</div>
						{this.state.rateProducts.length > 0 ? (
							<>
								{this.state.modal ? (
									<div className="rating-cont">
										<div className="rating-modal">
											<div className="modal-head">
												<p>How was the product ?</p>
												<i
													className="fas fa-times"
													onClick={() =>
														this.setState({ modal: false }, () => {
															document.body.setAttribute("style", "");
															window.scrollTo(0, this.windowOffSet);
														})
													}></i>
											</div>
											<div className="modal-body">
												{this.state.rateProducts.map((product, index) => {
													if (index === this.state.reviewProductCount && !product.rate) {
														return (
															<>
																<CheckOutCard2 product={this.state.rateProducts[this.state.reviewProductCount]} key={index} />
																<div className="rating">
																	<div className="stars">
																		<p>Rate : </p>
																		<i className={this.state.starCount > 0 ? "fas fa-star" : "far fa-star"} onClick={() => this.setState({ starCount: 1 })}></i>
																		<i className={this.state.starCount > 1 ? "fas fa-star" : "far fa-star"} onClick={() => this.setState({ starCount: 2 })}></i>
																		<i className={this.state.starCount > 2 ? "fas fa-star" : "far fa-star"} onClick={() => this.setState({ starCount: 3 })}></i>
																		<i className={this.state.starCount > 3 ? "fas fa-star" : "far fa-star"} onClick={() => this.setState({ starCount: 4 })}></i>
																		<i className={this.state.starCount > 4 ? "fas fa-star" : "far fa-star"} onClick={() => this.setState({ starCount: 5 })}></i>
																	</div>
																	{this.state.loggedIn ? (
																		<div className="review">
																			<label>Review</label>
																			<textarea name="review" cols="60" rows="2" placeholder="Write something..." onChange={this.handleChange}></textarea>
																		</div>
																	) : null}
																</div>
															</>
														);
													}
												})}
											</div>
											<div className="modal-footer">
												{this.state.btnLoading ? (
													<button type="button">
														<Lottie options={{ animationData: circleLoading }} width={25} height={25} />
													</button>
												) : (
													<button type="button" onClick={this.handleRate}>
														Rate
													</button>
												)}
											</div>
										</div>
									</div>
								) : null}
							</>
						) : null}
						{/* return */}
						{this.state.return ? (
							<div className="rating-cont">
								<div className="rating-modal">
									{this.state.returnNext === false ? (
										<>
											<div className="modal-head">
												<p>Select a product</p>
												<i
													className="fas fa-times"
													onClick={() =>
														this.setState({ return: false, selectedProduct: null, returnNext: false }, () => {
															document.body.setAttribute("style", "");
															window.scrollTo(0, this.windowOffSet);
														})
													}></i>
											</div>
											<div className="modal-body">
												{this.state.order &&
													this.state.order.products.map((product, index) => {
														if (product.status === "default") {
															return (
																<div
																	onClick={() => {
																		this.setState({
																			selectedProduct: product
																		});
																	}}
																	className={
																		this.state.selectedProduct && this.state.selectedProduct.id === product.id ? "selected-product active" : "selected-product"
																	}>
																	<CheckOutCard2 product={product} key={index} />
																	<div className="selected-product-overlay">
																		<i className="fas fa-check-circle"></i>
																	</div>
																</div>
															);
														}
													})}
												{this.state.selectedProduct ? (
													<button
														className="return-next"
														onClick={() => {
															this.setState({
																returnNext: true
															});
														}}>
														Next
													</button>
												) : null}
											</div>
										</>
									) : (
										<>
											<div className="modal-head">
												<i
													className="fas fa-arrow-left"
													onClick={() => {
														this.setState({
															returnNext: false
														});
													}}></i>
												<p>Why are you returning this : </p>
												<i
													className="fas fa-times"
													onClick={() =>
														this.setState({ return: false, selectedProduct: null, returnNext: false }, () => {
															document.body.setAttribute("style", "");
															window.scrollTo(0, this.windowOffSet);
														})
													}></i>
											</div>
											<div className="modal-body">
												<div className="dropdown">
													<select value={this.state.returnReason} onChange={(e) => this.setState({ returnReason: e.target.value })}>
														<option value="">Select a reason</option>
														<option value="Bought by mistake">Bought by mistake</option>
														<option value="Better price available">Better price available</option>
														<option value="Performance or quality not adequate">Performance or quality not adequate</option>
														<option value="Incompatible or not useful">Incompatible or not useful</option>
														<option value="Product damaged, but shipping box OK">Product damaged, but shipping box OK</option>
														<option value="Item arrived too late">Item arrived too late</option>
														<option value="Missing parts or accessories">Missing parts or accessories</option>
														<option value="Both product and shipping box damaged">Both product and shipping box damaged</option>
														<option value="Wrong items was sent">Wrong items was sent</option>
														<option value="Item defective or dosen't work">Item defective or dosen't work</option>
														<option value="Received item i didn't buy">Received item i didn't buy (no refund needed)</option>
														<option value="No longer neended">No longer neended</option>
														<option value="Did not approve purchase">Did not approve purchase</option>
														<option value="Inaccurate website description">Inaccurate website description</option>
													</select>
												</div>
												<button className="return-next done" onClick={this.handleReturn}>
													Return Item
												</button>
											</div>
										</>
									)}
								</div>
							</div>
						) : null}
						{/* replacement */}
						{this.state.replacement ? (
							<div className="rating-cont">
								<div className="rating-modal">
									{this.state.replacementNext === false ? (
										<>
											<div className="modal-head">
												<p>Select a product</p>
												<i
													className="fas fa-times"
													onClick={() =>
														this.setState({ replacement: false, selectedProduct: null, replacementNext: false }, () => {
															document.body.setAttribute("style", "");
															window.scrollTo(0, this.windowOffSet);
														})
													}></i>
											</div>
											<div className="modal-body">
												{this.state.order &&
													this.state.order.products.map((product, index) => {
														if (product.status === "default") {
															return (
																<div
																	onClick={() => {
																		this.setState({
																			selectedProduct: product
																		});
																	}}
																	className={
																		this.state.selectedProduct && this.state.selectedProduct.id === product.id ? "selected-product active" : "selected-product"
																	}>
																	<CheckOutCard2 product={product} key={index} />
																	<div className="selected-product-overlay">
																		<i className="fas fa-check-circle"></i>
																	</div>
																</div>
															);
														}
													})}
												{this.state.selectedProduct ? (
													<button
														className="return-next"
														onClick={() => {
															this.setState({
																replacementNext: true
															});
														}}>
														Next
													</button>
												) : null}
											</div>
										</>
									) : (
										<>
											<div className="modal-head">
												<i
													className="fas fa-arrow-left"
													onClick={() => {
														this.setState({
															replacementNext: false
														});
													}}></i>
												<p>Why do want to replace this item : </p>
												<i
													className="fas fa-times"
													onClick={() =>
														this.setState({ replacement: false, selectedProduct: null, replacementNext: false }, () => {
															document.body.setAttribute("style", "");
															window.scrollTo(0, this.windowOffSet);
														})
													}></i>
											</div>
											<div className="modal-body">
												<div className="dropdown">
													<select value={this.state.replacementReason} onChange={(e) => this.setState({ replacementReason: e.target.value })}>
														<option value="">Select a reason</option>
														<option value="Bought by mistake">Bought by mistake</option>
														<option value="Better price available">Better price available</option>
														<option value="Performance or quality not adequate">Performance or quality not adequate</option>
														<option value="Incompatible or not useful">Incompatible or not useful</option>
														<option value="Product damaged, but shipping box OK">Product damaged, but shipping box OK</option>
														<option value="Item arrived too late">Item arrived too late</option>
														<option value="Missing parts or accessories">Missing parts or accessories</option>
														<option value="Both product and shipping box damaged">Both product and shipping box damaged</option>
														<option value="Wrong items was sent">Wrong items was sent</option>
														<option value="Item defective or dosen't work">Item defective or dosen't work</option>
														<option value="Received item i didn't buy">Received item i didn't buy (no refund needed)</option>
														<option value="No longer neended">No longer neended</option>
														<option value="Did not approve purchase">Did not approve purchase</option>
														<option value="Inaccurate website description">Inaccurate website description</option>
													</select>
												</div>
												<button className="return-next done" onClick={this.handleReplacement}>
													Replace Item
												</button>
											</div>
										</>
									)}
								</div>
							</div>
						) : null}
						{/* cancel */}
						{this.state.cancel ? (
							<div className="rating-cont">
								<div className="rating-modal">
									{this.state.cancelNext === false ? (
										<>
											<div className="modal-head">
												<p>Select a product</p>
												<i
													className="fas fa-times"
													onClick={() =>
														this.setState({ cancel: false, selectedProduct: null, cancelNext: false }, () => {
															document.body.setAttribute("style", "");
															window.scrollTo(0, this.windowOffSet);
														})
													}></i>
											</div>
											<div className="modal-body">
												{this.state.order &&
													this.state.order.products.map((product, index) => {
														// if (product.status === "default") {
														return (
															<div
																onClick={() => {
																	this.setState({
																		selectedProduct: product
																	});
																}}
																className={this.state.selectedProduct && this.state.selectedProduct.id === product.id ? "selected-product active" : "selected-product"}>
																<CheckOutCard2 product={product} key={index} />
																<div className="selected-product-overlay">
																	<i className="fas fa-check-circle"></i>
																</div>
															</div>
														);
														// }
													})}
												{this.state.selectedProduct ? (
													<button
														className="return-next"
														onClick={() => {
															this.setState({
																cancelNext: true
															});
														}}>
														Next
													</button>
												) : null}
											</div>
										</>
									) : (
										<>
											<div className="modal-head">
												<i
													className="fas fa-arrow-left"
													onClick={() => {
														this.setState({
															cancelNext: false
														});
													}}></i>
												<p>Why do want to cancel this item : </p>
												<i
													className="fas fa-times"
													onClick={() =>
														this.setState({ replacement: false, selectedProduct: null, replacementNext: false }, () => {
															document.body.setAttribute("style", "");
															window.scrollTo(0, this.windowOffSet);
														})
													}></i>
											</div>
											<div className="modal-body">
												<div className="dropdown">
													<select value={this.state.cancelReason} onChange={(e) => this.setState({ cancelReason: e.target.value })}>
														<option value="">Select a reason</option>
														<option value="Bought by mistake">Bought by mistake</option>
														<option value="Better price available">Better price available</option>
														<option value="Performance or quality not adequate">Performance or quality not adequate</option>
														<option value="Incompatible or not useful">Incompatible or not useful</option>
														<option value="Product damaged, but shipping box OK">Product damaged, but shipping box OK</option>
														<option value="Item arrived too late">Item arrived too late</option>
														<option value="Missing parts or accessories">Missing parts or accessories</option>
														<option value="Both product and shipping box damaged">Both product and shipping box damaged</option>
														<option value="Wrong items was sent">Wrong items was sent</option>
														<option value="Item defective or dosen't work">Item defective or dosen't work</option>
														<option value="Received item i didn't buy">Received item i didn't buy (no refund needed)</option>
														<option value="No longer neended">No longer neended</option>
														<option value="Did not approve purchase">Did not approve purchase</option>
														<option value="Inaccurate website description">Inaccurate website description</option>
													</select>
												</div>
												<button className="return-next done" onClick={this.handleCancel}>
													Cancel Item
												</button>
											</div>
										</>
									)}
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		);
	}
}
