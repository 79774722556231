import React, { Component } from "react";
import "./ShippingPolicy.css";

class ShippingPolicy extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="shippingpolicy-container">
				<div className="header">
					<h1>ShippingPolicy</h1>
				</div>
				<div className="info">
					<div className="text">
						<p>The order will be packed and dispatched within 2 working days after order confirmation before 12pm.</p>
						<p>The order will delivered by the courier within the time given.</p>
						<p>It may vary from 2-14 days according to Pin Code and availability of customer.</p>
						<p>The number of attempt depends on the courier partner.</p>
						<p>If no one takes the delivery the order will be cancelled and the order is delivered back to us.</p>
						<p>There might be some delay due to some technical problems or regional issue or local issue or some other circumstances.</p>
						<p>The delivery charges vary from products to products.</p>
					</div>
				</div>
			</div>
		);
	}
}

export default ShippingPolicy;
