import React, { useState, useEffect } from "react";
// import "../settings/settings.css";
import firebase from "firebase";
import { PDFViewer } from "@react-pdf/renderer";
import { InvoiceComponent } from "./InvoiceComponent";
import { useLocation } from "react-router-dom";
// import { Loader } from "react-bootstrap-typeahead";

const BillDetail = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    // const billId = searchParams.get("id") || null;
    const code = searchParams.get("code") || null;
    const [loadingMsg, setLoaderMsg] = React.useState("Loading...");
    const [loader, setLoader] = React.useState(true);
    const [state, setState] = useState({
        "store": {

        },
        fakeBill: true,
        "cashTendered": 0,
        "purchasedProducts": [],
        "coupon": null,
        "cardBankName": "",
        "billDate": "2024-05-16",
        "discountType": "PRICE",
        "tempQty": 0,
        "discountValue": 0,
        "total": 17000,
        "upiAmount": 0,
        "products": [],
        "CGST": 1.09,
        "pointsRedeemed": 0,
        "pointsDiscount": 1236.87,
        "tempTotal": 0,
        "date": {
            "seconds": 1724326788,
            "nanoseconds": 454000000
        },
        "chequeBankName": "",
        "newCustomer": false,
        "SGST": 1.09,
        "discounts": [
            {
                "value": 0,
                "title": "Discount",
                "id": 1
            },
            {
                "value": 0,
                "title": "Staff Discount",
                "id": 2
            },
            {
                "title": "Review Discount",
                "value": 0,
                "id": 3
            },
            {
                "id": 4,
                "title": "Other Discount",
                "value": 0
            }
        ],
        "cardAmount": "2262.13",
        "tempCp": 0,
        "subtotal": 0,
        "storeId": null,
        "customerPoints": 50,
        "customer": [
            {
                "orders": [],
                "referalID": "RG4FFa",
                "uid": "h7RDdZtAJNaA00k3QQkIBLem3ED2",
                "email": "",
                "loginMethod": "Phone",
                "name": "Fazuan",
                "date": {
                    "seconds": 1722516681,
                    "nanoseconds": 122000000
                },
                "pointsHistory": [
                    {
                        "points": 50,
                        "reason": "SIGNUP BONUS",
                        "date": {
                            "seconds": 1722516681,
                            "nanoseconds": 122000000
                        }
                    }
                ],
                "cart": [],
                "couponsUsed": [],
                "wishlist": [],
                "alt": "",
                "gender": "",
                "points": 1236.87,
                "dob": "",
                "addresses": [],
                "id": "4Ae4sov0s4gmBUjHRG4F",
                "referredBy": [],
                "phone": "+917980657255"
            }
        ],
        "gst": 18,
        "tempTitle": "",
        "cardNumber": "",
        "tempSp": 0,
        "chequeAmount": 0,
        "customerPhone": null,
        "customerName": null,
        "customers": [],
        "billNo": "M/2024-2025/8",
        "customerEmail": null,
        "billGenerated": false,
        "cash": 0
    });

    useEffect(() => {
        // firebase.auth().onAuthStateChanged((user) => {
            // if (user) {
                handleInit();
            // } else {
            //     window.location.href = "/login";
            // }
        // });
    }, [code]);

    const handleInit = async () => {
        // console.log(billId);
        setLoader(true);
        // console.log(phone);
        (await firebase.firestore().collection("billings").where("billCode", "==", code)
            .where("deleted", "==", false)
            // .where("phone", "==", phone)
            .get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log(doc.data());
                    // doc.data() is never undefined for query doc snapshots
                    setState(doc.data());
                    // if()
                    // if(querySnapshot.empty()){
                        // return;
                    // }
                    if(doc.data().billNo){

        
                    setLoader(false)
                    return;
                    }
                    setLoaderMsg("No bill found.");

                });
            }));
    };

    React.useEffect(() => {
        console.log(state);
    }, [state]);

    const handleDiscountChange = (value, id, key) => {
        setState((prevState) => {
            const updatedDiscounts = prevState.discounts.map((discount) => {
                if (discount.id === id) {
                    return {
                        ...discount,
                        [key]: value
                    };
                }
                return discount;
            });

            return { ...prevState, discounts: updatedDiscounts };
        });
    }

    const totalDiscountsValue = () => {
        let sum = 0;
        state.discounts.forEach((discount) => {
            sum += Number(discount.value);
        });
        return sum;
    }

    const handleBillProductChange = (value, id, key) => {
        setState((prevState) => {
            const updatedProducts = prevState.purchasedProducts.map((product) => {
                if (product.id === id) {
                    return {
                        ...product,
                        [key]: value,
                        rate: key === "sp" ? Number((product.qty * value) / 1.18).toFixed(2) : product.rate,
                        total: key === "qty" ? value * product.sp : key === "sp" ? product.qty * value : key === "total" ? value : product.total
                    };
                }
                return product;
            });

            return { ...prevState, purchasedProducts: updatedProducts };
        });
    };

    const totalPriceOfPurchase = () => {
        let sum = 0;
        state.purchasedProducts.forEach((product) => {
            sum += Number(product.total);
        });
        return sum;
    }

    const totalCGSTOfPurchase = () => {
        return Number(totalPriceOfPurchase() - (totalPriceOfPurchase() / state.CGST)).toFixed(2);
    }

    const totalSGSTOfPurchase = () => {
        return Number(totalPriceOfPurchase() - (totalPriceOfPurchase() / state.SGST)).toFixed(2);
    }

    const totalQty = () => {
        let sum = 0;
        state.purchasedProducts.forEach((product) => {
            sum += Number(product.qty);
        });
        return sum;
    }

    return (
        <div className="settings-container">
            {/* <h1>{billId || ""}</h1> */}
            { loader ? <p>{loadingMsg}</p> : (
                <PDFViewer style={{ height: "500px", width: "100%" }}>
                    <InvoiceComponent
                        store={state.store}
                        billNo={state.billNo}
                        date={state.billDate}
                        time={state.time}
                        customerName={state.customer[0]?.name}
                        totalPrice={totalPriceOfPurchase()}
                        totalDiscount={totalDiscountsValue()}
                        pointsDiscount={state.pointsDiscount}
                        discounts={state.discounts}
                        cardNumber={state.cardNumber}
                        chequeNumber={state.chequeNumber}
                        cardAmount={state.cardAmount}
                        cash={(Number(totalPriceOfPurchase()) - Number(totalDiscountsValue()) - Number(state.pointsDiscount)) - Number(state.cardAmount) - (state.chequeAmount) - (state.upiAmount)}
                        upiAmount={state.upiAmount}
                        totalPcs={totalQty()}
                        products={state.purchasedProducts || []}
                    />
                </PDFViewer>
            )}
        </div>
    );
}

export default BillDetail;
