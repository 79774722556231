import React, { Component } from "react";
import "./Contact3.css";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Lottie from "lottie-react-web";
import loading from "../../../assets/loading.json";
import link from "../../../fetchPath";
import axios from "axios";
import toaster from "toasted-notes";

export class Contact3 extends Component {
	constructor() {
		super();
		this.state = {
			name: "",
			email: "",
			phone: "",
			message: "",
			sending: false,
		};
	}

	handleChange = (e) => {
		const { value, id } = e.target;
		this.setState({ [id]: value });
	};

	handleSubmit = async () => {
		if (this.state.name === "") {
			toaster.notify("Please enter your name!");
		} else if (this.state.email === "") {
			toaster.notify("Please enter your email!");
		} else if (this.state.phone === "") {
			toaster.notify("Please enter your phone!");
		} else if (this.state.message === "") {
			toaster.notify("Please type a message!");
		} else {
			this.setState({
				sending: true,
			});
			var { name, email, phone, message } = this.state;
			var data = {
				email: "faisal@marfit.in",
				subject: "MARFIT FRANCHISE CONTACT QUERY",
				message: `<p>Sender Name : ${name}</p>
                        <p>Sender Email : ${email}</p>
                        <p>Sender Contact No. : ${phone}</p>
                        <p>Sender Query : ${message}</p>
                `,
			};
			var res = await axios.post(link + "/api/sendemail", data);
			if (res.data !== null) {
				var data2 = {
					email: email,
					subject: "MARFIT FRANCHISE CONTACT QUERY",
					message: `<p>Thank You, for contacting Marfit.</p>
                            <p>We will get back with you as soon as possible.</p>
                    `,
				};
				var res2 = await axios.post(link + "/api/sendemail", data2);
				if (res2.data !== null) {
					this.setState({
						sending: false,
					});
					toaster.notify("Successfully Requested!");
				} else {
					toaster.notify("Error while sending request!");
				}
			} else {
				toaster.notify("Error while sending request!");
			}
		}
	};

	render() {
		return (
			<div className='contact-container'>
				<div className='contact-form-container'>
					<h1>Franchise Contact Form</h1>
					<div className='form-inputs'>
						<p>Name</p>
						<input className='form-input' onChange={this.handleChange} id='name' />
					</div>
					<div className='form-inputs'>
						<p>Email</p>
						<input className='form-input' id='email' onChange={this.handleChange} />
					</div>
					<div className='form-inputs'>
						<p>Phone</p>
						<PhoneInput
							country={"in"}
							onlyCountries={["in"]}
							disableDropdown={true}
							disableCountryCode={true}
							value={this.state.phone}
							onChange={(phone) => this.setState({ phone })}
							placeholder='Enter your phone number'
						/>
					</div>
					<div className='form-inputs'>
						<p>Message</p>
						<textarea className='form-input-area' id='message' onChange={this.handleChange} />
					</div>
					{this.state.sending ? <Lottie options={{ animationData: loading }} width={50} height={50} /> : <button onClick={this.handleSubmit}>Send</button>}
				</div>
			</div>
		);
	}
}

export default Contact3;
